import React, { useContext } from "react";
import { FaRegUserCircle, FaRegIdCard } from "react-icons/fa";
import styled from "../../../helpers/esm-styled-components";
import * as constants from "../../../constants/Constants";
import { theme } from "../../../helpers/GlobalStyles";
import Flag from "../../../UIComponents/Flag/Flag";
import { DetailSection } from "../DetailSection";
import CustomerDetail from "./CustomerDetail";
import { HorizontalDivider } from "../../../UIComponents/Modals/ModalUtils";
import { RequiredContainer, RequiredFields } from "./SearchByCustomer";
import { RequiredSpan } from "../../../UIComponents/Input/Input";
import CustomerEdit from "./CustomerEdit";
import {
  upperCaseFirstLetter,
  formatPhoneDisplay,
  getPrimaryInfo,
  isNullEmptyUndefined,
  isNullOrUndefined,
} from "../../../helper/validationHelper";
import { getFullName, isB2BAddressValid } from "../../../helper/CustomerHelper";
import Message from "../../../UIComponents/Error/Message";
import { copyToClipboard } from "../../../helper/simulateActions";
import { CVMContext } from "../../CVM/CVM";

const InfoSection = styled(DetailSection)`
  flex-grow: 1;
`;
const CustomerNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 1%;
`;
const CustomerDetailContainer = styled.div`
  display: flex;
  ${({ justify }) => justify && `justify-content:${justify};`};
  ${({ margin }) => margin && `margin:${margin}`}
`;
export const CustomerName = styled.div`
  font-family: ${theme.fontFamily.heavy};
  font-size: ${theme.fontSize.xLarge};
`;
const InformationContainer = styled.div`
  flex-grow: 1;
  min-width: 400px;
`;
// Icons
const CustomerIcon = styled(FaRegUserCircle)`
  width: 20px;
  height: 21px;
  left: 30px;
  margin-top: 2px;
  position: absolute;
`;
const CarIcon = styled.img`
  width: 20px;
  height: 21px;
  left: 30px;
  margin-top: 2px;
  position: absolute;
`;
// Flags
export const FlagContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  ${({ isEdit }) => isEdit && "flex-direction:column; align-items:center;"};
`;
const CustomerMissingFieldsIcon = styled(FaRegIdCard)`
  color: ${theme.colors.blue2};
  margin-left: 15px;
`;
const CustomerMissingFieldsLink = styled.a`
  color: ${theme.colors.blue2};
  cursor: pointer;
`;
const CustomerInformation = (props) => {
  const {
    isBusiness,
    isCustomerEdit,
    customerInfo,
    infoFields,
    handleInput,
    handleContactInput,
    isSRPMIC,
    isAAA,
    srpmicOpen,
    renderSRPMIC,
    updateDropdown,
    titleButtonType,
    titleButtonName,
    onButtonClick,
    isVTVAircheck,
    infoExpanded,
    fieldErrors,
    primaryFields,
    confirmClick,
    requiredMet,
    isSinglePoint,
    clearSuffix,
    isInspection,
    onButtonClick2,
  } = props;
  const { setToastrOn } = useContext(CVMContext);
  const isPrimaryAddress = getPrimaryInfo(customerInfo.addressCollection);
  const customerName = getFullName(customerInfo);
  const accountClosed =
    !isNullEmptyUndefined(customerInfo.blockedReasonCode) &&
    !isNullEmptyUndefined(customerInfo.postingBlock);
  const additionalInfo = () => {
    const businessContactInfo = customerInfo?.contactCollection
      ?.map((p) => p.contactItem)
      .filter((p) => p.isAdditionalContact === true);
    if (businessContactInfo?.length) {
      return businessContactInfo.slice(0, 2).map((key, i) => {
        return (
          <>
            <HorizontalDivider width="90%" margin="10px 0 15px 0" />
            <CustomerDetailContainer justify="flex-start">
              <CustomerDetail
                additional
                margin="0 5px 0 0"
                isLabel
                testId={`business_contact_${i + 1}`}
                infoName={`${constants.BUSINESS_CONTACT_LABEL} - `}
              />
              <CustomerDetail
                additional
                testId={`business_contact_name_${i + 1}`}
                infoName={`${upperCaseFirstLetter(
                  key.firstName || constants.EMPTY_STRING
                )}
                ${upperCaseFirstLetter(key.lastName || constants.EMPTY_STRING)}
                ${`- ${key.title || constants.NOT_APPLICABLE}`}`}
              />
            </CustomerDetailContainer>
            <CustomerDetailContainer margin="0 0 0 30px">
              <CustomerDetail
                width="30%"
                additional
                testId={`business_contact_mobile_${i + 1}`}
                infoName={`${constants.MOBILE_LABEL} ${
                  key.alternatePhone
                    ? `${formatPhoneDisplay(key.alternatePhone)}${
                        key.mobileExt
                          ? ` ext. ${key.mobileExt}`
                          : constants.EMPTY_STRING
                      }`
                    : constants.NOT_APPLICABLE
                }`}
              />
              <CustomerDetail
                width="30%"
                additional
                testId={`business_contact_phone_${i + 1}`}
                infoName={`${constants.PHONE_LABEL} ${
                  key.phone
                    ? `${formatPhoneDisplay(key.phone)}${` ext. ${
                        key.phoneExtension || constants.NOT_APPLICABLE
                      }`}`
                    : constants.NOT_APPLICABLE
                }`}
              />
              <CustomerDetail
                clickHandler={(e, text) =>
                  key?.email?.length > 0
                    ? copyToClipboard(text, setToastrOn)
                    : () => {}
                }
                width="40%"
                additional
                testId={`business_contact_email_${i + 1}`}
                infoName={` ${
                  key.email
                    ? key.email
                    : `${constants.EMAIL_LABEL} ${constants.NOT_APPLICABLE}`
                }`}
              />
            </CustomerDetailContainer>
          </>
        );
      });
    }
  };
  const isFleetInfo = () => {
    return (
      <>
        <HorizontalDivider width="90%" margin="10px 0 15px 0" />
        <CustomerDetailContainer justify="flex-start">
          <CustomerDetail
            additional
            margin="0 5px 0 0"
            isLabel
            testId="business_contact_"
            infoName={`${constants.FLEET_AGENT_LABEL} -`}
          />
          <CustomerDetail
            additional
            testId="business_contact_name_$"
            infoName={`${upperCaseFirstLetter(
              customerInfo?.fleetAgentFirstName || constants.EMPTY_STRING
            )}
                ${upperCaseFirstLetter(
                  customerInfo?.fleetAgentLastName || constants.EMPTY_STRING
                )}`}
          />
          <CustomerDetail
            isVTVOrAirCheck
            additional
            testId="business_contact_phone_$"
            infoName={` ${constants.PHONE_LABEL} ${
              customerInfo.fleetAgentPhoneNumber
                ? `${formatPhoneDisplay(customerInfo.fleetAgentPhoneNumber)}`
                : constants.NOT_APPLICABLE
            }`}
            margin="0 0 0 54px"
          />
        </CustomerDetailContainer>
      </>
    );
  };
  // Error string builder for required customer info fields to handle passing react icon component into Message component
  const getReqCustomerInfoErrorMsg = () => {
    return [
      "Please enter missing Customer Information before proceeding",
      <CustomerMissingFieldsIcon />,
      <CustomerMissingFieldsLink onClick={(e) => onButtonClick(e, true)}>
        {" "}
        Add missing customer information
      </CustomerMissingFieldsLink>,
    ];
  };
  return (
    <InfoSection
      title={constants.TITLE_CUSTOMER_INFORMATION}
      titleButtonType={titleButtonType}
      titleButtonName={titleButtonName}
      isInspection={isInspection}
      onButtonClick={onButtonClick}
      onButtonClick2={onButtonClick2}
      info
    >
      {!customerInfo.isGKValid &&
        customerInfo.customerType === constants.STANDARD_CUSTOMER_TYPE &&
        Object.keys(customerInfo)?.filter((k) => k === "isGKValid")?.length >
          0 &&
        !isCustomerEdit && (
          <Message
            errorMessages={getReqCustomerInfoErrorMsg()}
            type={constants.WARNING}
            manualWidth="94%"
          />
        )}
      {accountClosed && (
        <Message
          errorMessages={[constants.CLOSED_ACCOUNT_ERROR]}
          type={constants.WARNING}
          manualWidth="94%"
        />
      )}
      {isBusiness ? (
        <>
          <CarIcon src="./VehicleImages/b2b-black.png" />
        </>
      ) : (
        <CustomerIcon />
      )}
      <CustomerNameContainer>
        {isCustomerEdit && (
          <CustomerEdit
            formType={constants.CUSTOMER_EDIT_INFO_FORM}
            customerInfo={customerInfo}
            fields={infoFields}
            fieldErrors={fieldErrors}
            handleInput={handleInput}
            updateDropdown={updateDropdown}
            srpmicOpen={srpmicOpen}
            renderSRPMIC={renderSRPMIC}
            isSRPMIC={isSRPMIC}
            isAAA={isAAA}
            clearSuffix={clearSuffix}
          />
        )}
        {!isCustomerEdit && (
          <>
            <CustomerName>{customerName}</CustomerName>
            {isVTVAircheck && !isCustomerEdit && (
              <CustomerDetail
                margin="5px 0 0 0"
                testId="purchasing_phone"
                infoName={customerInfo.phoneNumber}
              />
            )}
            {isVTVAircheck && !isCustomerEdit && (
              <CustomerDetail
                clickHandler={(e, text) =>
                  customerInfo?.email?.length > 0
                    ? copyToClipboard(text, setToastrOn)
                    : () => {}
                }
                hasOverflow={customerInfo?.email?.length > 32}
                margin="5px 0 0 0"
                testId="purchasing_email"
                infoName={customerInfo.email}
              />
            )}
          </>
        )}
        <FlagContainer isEdit={isCustomerEdit}>
          {customerInfo.isMyAccount && (
            <Flag
              isVTVOrAirCheck
              notTable
              flagName={constants.MY_ACCOUNT_FLAG}
            />
          )}
          {accountClosed && (
            <Flag notTable flagName={constants.ACCOUNT_STATUS} />
          )}
          {!isNullEmptyUndefined(customerInfo.blockedReasonCode) &&
            isNullEmptyUndefined(customerInfo.postingBlock) && (
              <Flag
                infoExpanded={infoExpanded}
                isVTVAircheck={isVTVAircheck}
                notTable
                flagName={constants.AR_CHARGE_FLAG}
              />
            )}
        </FlagContainer>
      </CustomerNameContainer>
      {!isVTVAircheck && !isCustomerEdit && (
        <>
          <CustomerDetailContainer>
            <InformationContainer
              width="270px"
              data-testid="customerAddressCityStateZip"
            >
              {!isBusiness ||
              customerInfo?.companyStatus ===
                constants.BUSINESS_COMPANY_CUSTOMER ||
              isB2BAddressValid(customerInfo) ? (
                <>
                  <CustomerDetail
                    testId="address_line"
                    infoName={
                      isBusiness
                        ? `${
                            upperCaseFirstLetter(
                              isPrimaryAddress?.addressLine1
                            ) || constants.NOT_APPLICABLE
                          }${
                            isPrimaryAddress?.addressLine2
                              ? `, ${upperCaseFirstLetter(
                                  isPrimaryAddress?.addressLine2
                                )}`
                              : constants.EMPTY_STRING
                          }
                        `
                        : `${
                            upperCaseFirstLetter(customerInfo?.address1) ||
                            constants.NOT_APPLICABLE
                          }${
                            customerInfo?.address2
                              ? `, ${upperCaseFirstLetter(
                                  customerInfo?.address2
                                )}`
                              : constants.EMPTY_STRING
                          }
                        `
                    }
                  />
                  <CustomerDetail
                    testId="city_state_zip"
                    infoName={
                      isBusiness
                        ? `${
                            upperCaseFirstLetter(isPrimaryAddress?.city) ||
                            constants.NOT_APPLICABLE
                          }, ${
                            isPrimaryAddress?.region || constants.EMPTY_STRING
                          } ${
                            isPrimaryAddress?.postalCode ||
                            constants.EMPTY_STRING
                          }`
                        : `${
                            upperCaseFirstLetter(customerInfo?.city) ||
                            constants.NOT_APPLICABLE
                          }, ${customerInfo?.state || constants.EMPTY_STRING} ${
                            customerInfo?.zip || constants.EMPTY_STRING
                          }`
                    }
                  />
                </>
              ) : (
                primaryFields && (
                  <CustomerEdit
                    isB2BEdit
                    formType={constants.CUSTOMER_EDIT_PRIMARY_FORM}
                    fields={primaryFields}
                    handleInput={handleContactInput}
                    updateDropdown={updateDropdown}
                    confirmClick={confirmClick}
                    fieldErrors={fieldErrors}
                    requiredMet={requiredMet}
                    isSummaryViewEdit
                  />
                )
              )}
              {!isBusiness && (
                <CustomerDetail
                  clickHandler={(e, text) =>
                    customerInfo?.email?.length > 0
                      ? copyToClipboard(text, setToastrOn)
                      : () => {}
                  }
                  testId="purchasing_email"
                  hasOverflow={customerInfo?.email?.length > 32}
                  infoName={
                    customerInfo.email
                      ? customerInfo.email
                      : `${constants.EMAIL_LABEL} ${constants.NOT_APPLICABLE}`
                  }
                />
              )}
            </InformationContainer>
            <InformationContainer>
              {isBusiness && (
                <>
                  <CustomerDetail
                    isLabel
                    testId="purchasing_contact"
                    infoName={upperCaseFirstLetter(
                      constants.PURCHASING_CONTACT
                    )}
                  />
                  <CustomerDetail
                    testId="purchasing_name"
                    infoName={
                      customerInfo?.firstName && customerInfo?.lastName
                        ? `${upperCaseFirstLetter(
                            customerInfo?.firstName
                          )} ${upperCaseFirstLetter(customerInfo?.lastName)} ${
                            customerInfo?.title
                              ? ` - ${customerInfo.title}`
                              : constants.EMPTY_STRING
                          }`
                        : `${constants.NOT_APPLICABLE}`
                    }
                  />
                </>
              )}
              <CustomerDetail
                testId="purchasing_mobile"
                infoName={`${constants.MOBILE_LABEL} ${
                  isBusiness
                    ? customerInfo.alternatePhone
                      ? `${formatPhoneDisplay(customerInfo.alternatePhone)}`
                      : constants.NOT_APPLICABLE
                    : customerInfo?.mobileNumber
                    ? customerInfo?.mobileNumber
                    : constants.NOT_APPLICABLE
                }`}
              />
              {!isBusiness && (
                <CustomerDetail
                  testId="purchasing_work"
                  infoName={`${constants.WORK_LABEL} ${
                    customerInfo.workNumber
                      ? ` ${formatPhoneDisplay(customerInfo.workNumber)}`
                      : constants.NOT_APPLICABLE
                  } ${
                    customerInfo.workExt
                      ? `ext. ${customerInfo.workExt}`
                      : constants.EMPTY_STRING
                  }`}
                />
              )}
              <CustomerDetail
                testId="purchasing_phone"
                infoName={`${
                  isBusiness ? constants.PHONE_LABEL : constants.HOME_LABEL
                } ${
                  (isBusiness
                    ? formatPhoneDisplay(isSinglePoint?.contactItem.phone)
                    : customerInfo.phoneNumber) || constants.NOT_APPLICABLE
                }  ${
                  customerInfo.phoneExtension
                    ? `ext. ${customerInfo.phoneExtension}`
                    : constants.EMPTY_STRING
                }`}
              />
              <CustomerDetail
                clickHandler={(e, text) => copyToClipboard(text, setToastrOn)}
                hasOverflow={customerInfo?.email?.length > 26}
                emailMaxWidth
                testId="purchasing_email"
                value={
                  isBusiness && customerInfo?.email
                    ? customerInfo.email
                    : constants.NOT_APPLICABLE
                }
                infoName={
                  isBusiness &&
                  `${constants.EMAIL_LABEL} ${
                    customerInfo?.email
                      ? customerInfo.email
                      : constants.NOT_APPLICABLE
                  }`
                }
              />
            </InformationContainer>
          </CustomerDetailContainer>
          {isBusiness && additionalInfo()}
          {isBusiness && isFleetInfo()}
          <FlagContainer>
            {customerInfo.isSynchronyCardholder && (
              <Flag flagName={constants.DTCC_FLAG} />
            )}
            {customerInfo.isMilitary && (
              <Flag flagName={constants.MILITARY_FLAG} />
            )}
            {customerInfo.AAA && isAAA && (
              <Flag flagName={constants.AAA_FLAG} />
            )}
          </FlagContainer>
        </>
      )}
    </InfoSection>
  );
};

export default CustomerInformation;
