import { useContext, useEffect, useState } from 'react';
import StorageShim from 'node-storage-shim';

import { CVMContext } from '../../components/CVM/CVM';
import VehicleModal from './VehicleModals/StandardVehicle/VehicleModal';
import AddressModal from './AddessModals/AddressModal';
import SearchModal from './SearchModals/SearchModal';
import * as constants from '../../constants/Constants';
import {
  defaultInspectionQuery, initialCustomerFormData, initialVehicleFormData
} from '../../helper/baseDataHelper';
import styled from '../../helpers/esm-styled-components';
import { ModalWrapper, ModalOverlay } from './ModalUtils';
import { APIRouter } from '../../Data/APIRouter';
import { reactivateDeactivateVehicle } from './VehicleModals/StandardVehicle/VehicleHelper';
import { clearAlternateInfo, getFormattedUpdateCustomer } from '../../helper/CustomerHelper';
import ImportedVehicleModal from './VehicleModals/ImportedVehicleModal';

import { formatPhoneDisplay, isNullEmptyUndefined } from '../../helper/validationHelper';
import { getFormattedInspectionData, vtvAircheckBaseData } from '../../helper/vehicleFormValidation';

const sessionStorage = globalThis.sessionStorage || new StorageShim();


const Modal = ({ modalRef, type }) => {
  const {
    aircheckSelected,
    csrSelected,
    customerInfo,
    deactivatedVehicles,
    dvrSelected,
    isModalOn,
    modalContent,
    modalDetails,
    moduleName,
    previousOrigin,
    searchOrigin,
    setAircheckSelected,
    setCSRSelected,
    setCustomerFields,
    setCustomerInfo,
    setDeactivatedVehicles,
    setDVRSelected,
    setErrorMessage,
    setErrorMessageObj,
    setIsManualVtv,
    setIsModalOn,
    setIsToggle,
    setLoader,
    setModalContent,
    setModalDetails,
    setModuleName,
    setPreviousOrigin,
    setSearchOrigin,
    setSearchQuery,
    setSearchResults,
    selectedVehicle,
    setSelectedVehicle,
    setShowViewAllVehiclesLink,
    setShowDeactivatedVehicles,
    setVehSelected,
    setVehicleFields,
    setVehicles,
    setVtvSelected,
    showDeactivatedVehicles,
    vehicles,
    vtvSelected,
    setCurrentCV
  } = useContext(CVMContext);

  useEffect(() => {
    // Focus on the modalRef when the child component mounts
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [modalRef]);

  const modalAction = async (event, callBack) => {
    const buttonName = event.target.getAttribute('name');
    const isCustomerSearchModule = moduleName === constants.MODULE_CUSTOMER_SEARCH;
    const isVehicleSearchModule = moduleName === constants.MODULE_VEHICLE_SEARCH;
    const isCustomerSummary = moduleName === constants.MODULE_CUSTOMER_SUMMARY;

    // NOTE: This route is proceeding with summary selected vehicle
    if (buttonName === constants.MODAL_OVERRIDE_SELECTED_VEHICLE_BUTTON) {
      // Fastest way to override the vehicle is by vehicle id
      let inspection;

      if (vtvSelected) {
        inspection = { ...vtvSelected, customerVehicleId: selectedVehicle.vehicleDetails.id };
        console.log(`Executing override with selected vehicle: ${inspection}`);
        setVtvSelected(inspection);
      } else {
        inspection = { ...aircheckSelected, customerVehicleId: selectedVehicle.vehicleDetails.id };
        console.log(`Executing override with selected vehicle: ${inspection}`);
        setAircheckSelected(inspection);
      }
      // NOTE: Need to run an update to VTV / Aircheck to reflect the new selected vehicle override
      const inspectionData = getFormattedInspectionData(vtvAircheckBaseData(inspection), selectedVehicle, customerInfo);
      const inspectionRes = await APIRouter('VTV', 'updateVTVInspection', inspectionData, setLoader);
      if (inspectionData.data === 'service') { setVtvSelected({ inspectionId: inspectionRes.inspectionId }); } else { setAircheckSelected({ inspectionId: inspectionRes.inspectionId }); }
      setSelectedVehicle(null);
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      setIsModalOn(false);
      return;
    }

    // NOTE: This route is proceeding with summary inspection vehicle
    if (buttonName === constants.MODAL_OVERRIDE_INSPECTION_VEHICLE_BUTTON) {
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      vtvSelected ? setVtvSelected(vtvSelected) : setAircheckSelected(aircheckSelected);
      setSelectedVehicle(null);
      setSearchOrigin(searchOrigin);
      setIsModalOn(false);
      return;
    }

    // This needs refactoring
    if (buttonName === constants.BACK_TO_CUSTOMER_SUMMARY_NO_SAVE) {
      setVtvSelected(null);
      setAircheckSelected(null);
      setIsManualVtv(false);
      setIsModalOn(false);
      setModalDetails(null);
      return;
    }
    if (buttonName === constants.CLEAR_SEARCH_MODAL_CONFIRM_BUTTON
      || buttonName === constants.NEW_SEARCH_MODAL_CONFIRM_BUTTON) {
      setModuleName(constants.MODULE_SEARCH);
      document.getElementById('phone')?.focus();
      setCustomerFields(initialCustomerFormData);
      setVehicleFields(initialVehicleFormData);
      setSearchQuery({});
      setSearchOrigin(null);
      setPreviousOrigin({});
      setCustomerInfo({});
      setErrorMessageObj({});
      setErrorMessage(constants.EMPTY_STRING);
      setVehSelected(null);
      setSelectedVehicle(null);
      setVtvSelected(null);
      setAircheckSelected(null);
      setIsModalOn(false);
      setCurrentCV({});
      // Check the inspection
      return;
    }

    if (buttonName === constants.CUSTOMER_NOT_FOUND_CANCEL_BUTTON) {
      const inspectionCustomer =  vtvSelected?.customer || aircheckSelected?.customer;
      const {
        firstName, lastName, phone, email
      } = inspectionCustomer;
      setCustomerFields({
        ...initialCustomerFormData,
        firstName,
        lastName,
        phoneNumber: formatPhoneDisplay(phone),
        email
      });
      setIsModalOn(false);
      setModuleName(constants.MODULE_CREATE_CUSTOMER);
      return;
    }

    if (buttonName === constants.CUSTOMER_NOT_FOUND_CONFIRM_BUTTON) {
      setIsModalOn(false);
      setModuleName(constants.MODULE_VEHICLE_SEARCH);
      return;
    }

    if (isCustomerSearchModule || isVehicleSearchModule) {
      setSearchOrigin(null);
      setModuleName(constants.MODULE_SEARCH);
    }
    if (buttonName === constants.NO_CUSTOMER_MODAL_CONFIRM_BUTTON) {
      setModuleName(constants.MODULE_CREATE_CUSTOMER);
    }
    if (buttonName === constants.HOLDING_INSPECTION_MODAL_CONFIRM_BUTTON) {
      csrSelected && setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      setIsModalOn(false);
      return;
    }


    // imported vehicle modal actions
    if (buttonName === constants.IMPORTED_VEHICLE_MODAL_CONFIRM_BUTTON || buttonName === constants.IMPORTED_VEHICLE_MODAL_CANCEL_BUTTON) {
      callBack(buttonName === constants.IMPORTED_VEHICLE_MODAL_CONFIRM_BUTTON);
      return;
    }

    /* Refactor of return to search results from summary: */
    // Simplified switch between the routes
    if (buttonName === 'Continue') {
      callBack();
    } else if (isCustomerSummary && !dvrSelected) {
      switch (searchOrigin) {
        case constants.SEARCH_ORIGIN_CUSTOMER:
        default:
          setVtvSelected(null);
          setAircheckSelected(null);
          if (previousOrigin.results)setSearchQuery(previousOrigin.searchQuery);
          setSearchOrigin(constants.SEARCH_ORIGIN_CUSTOMER);
          setModuleName(constants.MODULE_CUSTOMER_SEARCH);
          break;
          // These all have the same logic when returing
        case constants.SEARCH_ORIGIN_AIRCHECK:
        case constants.SEARCH_ORIGIN_VTV:
        case constants.SEARCH_ORIGIN_LICENSE_PLATE:
        case constants.SEARCH_ORIGIN_VIN:
          if (searchOrigin === constants.SEARCH_ORIGIN_VIN || searchOrigin ===  constants.SEARCH_ORIGIN_LICENSE_PLATE) {
            setVtvSelected(null);
            setAircheckSelected(null);
          }
          // NOTE: Need to incorporate the reroute. Will comment out for now
          if (previousOrigin.origin === constants.SEARCH_ORIGIN_CUSTOMER
            || previousOrigin.origin === constants.SEARCH_ORIGIN_VIN ||
            previousOrigin.origin === constants.SEARCH_ORIGIN_LICENSE_PLATE) {
            setSearchOrigin(null);
            setSearchResults(previousOrigin.results);
            previousOrigin.origin !== constants.SEARCH_ORIGIN_CUSTOMER ? setModuleName(constants.MODULE_VEHICLE_SEARCH) : setModuleName(constants.MODULE_CUSTOMER_SEARCH);
            setVtvSelected(null);
            setAircheckSelected(null);
            break;
          } else {
            if (buttonName === constants.NEW_SEARCH_MODAL_CONFIRM_BUTTON) {
              setVtvSelected(null);
              setAircheckSelected(null);
              break;
            }
            if (searchOrigin === constants.SEARCH_ORIGIN_VTV || searchOrigin === constants.SEARCH_ORIGIN_AIRCHECK) {
              setSearchOrigin(searchOrigin === constants.SEARCH_ORIGIN_VTV ? constants.SEARCH_ORIGIN_VTV : constants.SEARCH_ORIGIN_AIRCHECK);
              setPreviousOrigin({});
              setVtvSelected({});
              setAircheckSelected({});
            }
            if (searchOrigin !== constants.SEARCH_ORIGIN_LICENSE_PLATE && searchOrigin !== constants.SEARCH_ORIGIN_VIN)setSearchQuery(defaultInspectionQuery());
            setModuleName(constants.MODULE_VEHICLE_SEARCH);
            break;
          }
        case constants.SEARCH_ORIGIN_CREATE_CUSTOMER:
          setModuleName(constants.MODULE_SEARCH);
          break;
      }
      // Resetters for every route from back to search results
      setSelectedVehicle(null);
      setShowDeactivatedVehicles(false);
      setCustomerInfo({});
      setIsModalOn(false);
      return;
    }
    // This needs to be done in the object declaration
    if (buttonName === constants.CUSTOMER_EDIT_MODAL_CONFIRM_BUTTON || buttonName === constants.CLEAR_EDIT_CONFIRM_BUTTON) {
      if (modalDetails.data.id) {
        try {
          // Customer Create Restructure
          const customerData = {
            ...modalDetails.data,
            SRPMICNumber: constants.EMPTY_STRING
          };

          if (modalDetails.isClearAlternate) {
            customerData.alternate = clearAlternateInfo(customerData.alternate);
          }
          const formattedCustomer = getFormattedUpdateCustomer(customerData, customerInfo);
          if (!isNullEmptyUndefined(formattedCustomer?.SRPMICNumber)) {
            sessionStorage.setItem(`${formattedCustomer?.id}.SRPMICNumber`,
              formattedCustomer?.SRPMICNumber);
          }
          const res = await APIRouter('C360', 'updateCustomer', formattedCustomer, setLoader, false);
          setCSRSelected({ id: res.customer.customerId });
          setCustomerInfo({ SRPMICNumber: modalDetails.data.SRPMICNumber, taxExempt: customerInfo.taxExempt || {} });
          setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
        } catch (e) {
          //  TODO: replace with proper error handling
          console.error(e);
        }
      }
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
    }
    setIsToggle(false);
    if (moduleName === constants.MODULE_CUSTOMER_VEHICLES || moduleName === constants.MODULE_CUSTOMER_SUMMARY) {
      if (dvrSelected.isIncomplete) {
        setModalDetails({
          type: constants.MODAL_TYPE_ADD_NEW_VEHICLE,
          data: { vehicleDetails: dvrSelected },
        });
        setIsModalOn(true);
      } else {
        setShowDeactivatedVehicles(false);
        setDVRSelected(null);
        // TODO: Place to update backend with re-activating vehicle
        // sets the vehicle selected back to activated in memory
        if (dvrSelected) {
          let selectedAssembly = dvrSelected?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem || dvrSelected.assemblyCollection[0]?.assemblyItem;
          if (selectedAssembly.frontTireDiameter !== selectedAssembly.rearTireDiameter) {
            selectedAssembly =
              [
                dvrSelected.assemblyCollection.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem || dvrSelected.assemblyCollection[0]?.assemblyItem,
                dvrSelected.assemblyCollection.filter(ac => ac.assemblyItem.currentAssembly)[0]?.assemblyItem || dvrSelected.assemblyCollection[0]?.assemblyItem
              ];
          }
          dvrSelected.selectedAssembly = selectedAssembly;
          const type = dvrSelected.type === 'Generic' ? 'Generic' : 'Fitment';
          if (modalDetails.type !== constants.MODAL_TYPE_SEARCH) {
            reactivateDeactivateVehicle(customerInfo, dvrSelected,
              dvrSelected.vehicleId, dvrSelected.relationshipId, type,
              'reactivate', setLoader, selectedVehicle, setSelectedVehicle, setModuleName,
              setIsModalOn, vehicles, deactivatedVehicles, setVehicles,
              setDeactivatedVehicles, setShowViewAllVehiclesLink);
          }
        }
      }
      if (showDeactivatedVehicles) {
        if (buttonName === constants.REACTIVATE_VEHICLE_MODAL_CONFIRM_BUTTON && vehicles.length < 4) {
          setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
        } else {
          setModuleName(constants.MODULE_CUSTOMER_VEHICLES);
        }
      }
      buttonName === constants.CUSTOMER_BACK_TO_SEARCH_MODAL_CONFIRM_BUTTON && setModuleName(constants.MODULE_CUSTOMER_SEARCH);

      if (dvrSelected.isIncomplete) {
        // run away to prevent modal closing, so we can use the edit vehicle modal
        return;
      }
    }
    setIsModalOn(false);
    setModalDetails({});
  };

  const returnToModule = () => {
    setModalDetails({});
    setIsModalOn(false);
  };


  // determines which type of modal to pop
  useEffect(() => {
    setModalContent(() => {
      switch (type) {
        case constants.MODAL_TYPE_REACTIVATE_VEHICLE:
        case constants.MODAL_TYPE_TAX_CONFIRMATION_MODAL:
        case constants.MODAL_TYPE_SEARCH:
        case constants.MODAL_TYPE_CLEAR_EDIT:
          return (
            <SearchModal
              returnToModule={returnToModule}
              modalAction={(e) => modalAction(e, modalDetails.callBack)}
              title={modalDetails.title}
              subtitle={modalDetails.subtitle}
              modalBody={modalDetails.modalBody}
              cancelButtonName={modalDetails.cancelButton}
              confirmButtonName={modalDetails.confirmButton}
              // See if you don't even need to pass just make the call here
              customer={modalDetails.data}
            />
          );
        case constants.MODAL_TYPE_ADD_NEW_VEHICLE:
          return (
            <VehicleModal
              returnToModule={returnToModule}
              subtitle={modalDetails.subTitle}
              vehicle={modalDetails.data}
              useImportedVehicle={modalDetails.useImportedVehicle}
              incompleteInspectionVehicle={modalDetails.incompleteInspectionVehicle}
            />
          );
        case constants.RADIO_FORM_ADDRESS_VERIFY:
        case constants.RADIO_FORM_ADDRESS_ONE_OPTION:
          return (
            <AddressModal
              returnToModule={returnToModule}
              modalAction={modalDetails.onSubmit}
              type={modalDetails.type}
              title={modalDetails.title}
              subtitle={modalDetails.subtitle}
              values={modalDetails.values}
              onSubmit={modalDetails.onSubmit}
              onEdit={modalDetails.onEdit}
            />
          );
        case constants.IMPORTED_VEHICLE_MODAL:
          return (
            <ImportedVehicleModal
              returnToModule={(e) => modalAction(e, modalDetails.callBack)}
              modalAction={(e) => modalAction(e, modalDetails.callBack)}
              title={modalDetails.title}
              subtitle={modalDetails.subtitle}
              modalBody={modalDetails.modalBody}
              cancelButtonName={modalDetails.cancelButton}
              confirmButtonName={modalDetails.confirmButton}
              // See if you dont even need to pass just make the call here
              customer={modalDetails.data}
            />
          );
        default:
          break;
      }
    });

    return () => {
      setModalContent(null);
    };
  }, [isModalOn, modalDetails]);

  useEffect(() => {
    const escKeyPress = (e) => {
      e.keyCode === constants.ESCAPE_KEY_CODE && returnToModule(e);
    };
    document.addEventListener('keydown', (e) => escKeyPress(e), false);
    return () => {
      document.removeEventListener('keydown', escKeyPress, false);
    };
  }, [returnToModule]);

  return (
    <ModalWrapper ref={modalRef} tabIndex={modalRef.current ? null : -1} >
      {modalContent}
    </ModalWrapper>
  );
};

export default Modal;
