import React, { useContext, useEffect, useState } from 'react';

import debug from 'debug';

import styled from '../../../helpers/esm-styled-components';

import * as constants from '../../../constants/Constants';
import { theme } from '../../../helpers/GlobalStyles';
import { CVMContext } from '../../CVM/CVM';
import {
  checkForEmptyObject,
  checkIfAnyFieldIsFilled,
  checkRequiredFields,
  formErrorControl,
  isEmptyArray,
  isEmptyObject,
  isNullOrUndefined,
} from '../../../helper/validationHelper';
import Input, { RequiredSpan } from '../../../UIComponents/Input/Input';
import DetailSection from '../DetailSection';
import Dropdown from '../../../UIComponents/Dropdown/Dropdown';
import SearchButton from '../../../UIComponents/Button/SearchButton';
import Message from '../../../UIComponents/Error/Message';
import {
  addressVerification,
  customerFormValidation,
  fetchZip,
  validateCustomerOnCreate,
  validateCustomerOnSearch,
} from '../../../helper/customerFormValidation';
import {
  countryOptions,
  phoneTypeOptions,
  phoneOptions,
  getStateOrProvincePlaceholder,
  getZipCodeLabel,
  formatCustomerRequest,
} from '../../../helper/baseDataHelper';
import { APIContext, isSRPMICSite } from '../../../Data/API';
import PhoneCountryDropdown from '../../../UIComponents/Dropdown/PhoneCountryDropdown';
import Checkbox from '../../../UIComponents/Checkbox/Checkbox';
import { APIRouter } from '../../../Data/APIRouter';
import { editFieldLengthMet } from '../../../helper/CustomerHelper';

const logger = debug('ui');

const DetailGrid = styled.div`
  display: grid;
  ${({ fieldErrors }) => `
    grid-row-gap: ${checkForEmptyObject(fieldErrors) ? '30px' : '60px'};
  `};
  grid-column-gap: 30px;
  grid-template-columns: repeat(5, 335px);
  grid-template-rows: repeat(3, 40px);
  grid-template-areas:
    "phone email name search"
    "address addropt citystate create"
    "zipcountry company fleetemp .";
  ${({ isCreate }) => isCreate &&
    'grid-template-areas: "name phone phonetype search" "email address addropt ." "zip city statecountry ."" company military srpmic ."'};
`;
const SRPMICMilitaryArea = styled.div`
display: inline-flex;
grid-area: military;
grid-column-gap: 15px;
grid-template-columns: repeat(2, 10px);
grid-template-areas:
    " . ";
`;
const ShortDropdown = styled(Dropdown)`
  display: inline-flex;
`;
const SearchInput = styled(Input)`
  font-size: 12px;
`;
const ShortInput = styled(SearchInput)`
  display: inline-block;
  width: 50%;
`;
const NameInputs = styled.div`
  grid-area: name;
  position: relative;
`;
const FirstName = styled(ShortInput)`
  width: calc(50% - 8px);
  margin-right: 8px;
`;
const LastName = styled(ShortInput)`
  width: calc(50% - 8px);
  margin-left: 8px;
`;
const AddressLine1 = styled(SearchInput)`
  grid-area: address;
`;
const ZipCountry = styled.div`
  grid-area: zipcountry;
`;
const PhoneNumber = styled(SearchInput)``;
const PhoneType = styled(ShortDropdown)`
  grid-area: phonetype;
`;
const AddressOptional = styled(SearchInput)`
  grid-area: addropt;
`;
const CompanyName = styled(SearchInput)`
  grid-area: company;
`;
export const SRPMICWrapper = styled.div`
  grid-area: srpmic;
  display: flex;
  ${({ isClosed }) => isClosed && 'width:100px;'}
`;
export const SRPMICLabel = styled.div`
  margin-bottom: 5px;
  margin-left: 5px;
`;
export const MilitaryLabel = styled.div`
  margin-bottom: 5px;
  margin-left: 5px;
  min-width: 130px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SRPMICCheckbox = styled(Checkbox)`
   margin: 0;
`;
export const SRPMICInput = styled(SearchInput)`
  width: 195px;
  margin-left: 43px;
  ${({ edit }) => edit && 'min-width:250%; margin-left:8%;'}
`;
export const MilitaryCheckbox = styled(Checkbox)`
margin: 0;
`;

const MilitaryWrapper = styled.div`
  display: flex;
  width: min-content;
  margin-right: 20px;
  ${({ isCreate }) => (isCreate ? 'width: %; grid-area: military; top:0;' : 'margin-right: 80px')}
`;
const EditCheckboxWrapper = styled(CheckboxWrapper)`
  margin-right: 05px;
`;


const StateProvince = styled(ShortDropdown)`
  width: calc(50% - 8px);
  ${({ isCreate }) => `
  margin: ${!isCreate ? '0 0 0 8px' : '0 8px 0 0'};
`}
`;
const Country = styled(ShortDropdown)`
  grid-area: country;
  width: calc(50% - 8px);
  margin-left: 8px;
  ${({ isCreate }) => isCreate && 'width: 100%; grid-area: zip; top:0;'}
`;
const StateCountry = styled.div`
  grid-area: statecountry;
  display: flex;
`;
const ZipCode = styled(ShortInput)`
  width: calc(50% - 8px);
  top: -19px; /* reverse offset from dropdown */
  ${({ isCreate }) => (isCreate ? 'width: 100%; grid-area: zip; top:0;' : 'margin-right: 8px')}
`;
const EmailAddressContainer = styled.div`
  grid-area: email;
  position: relative;
`;
const PhoneNumberContainer = styled(EmailAddressContainer)`
  grid-area: phone;
`;
const CityState = styled.div`
  grid-area: citystate;
`;
const CityName = styled(ShortInput)`
  width: calc(50% - 8px);
  margin-right: 8px;
  top: -19px;
`;
const CityNameCreate = styled(SearchInput)`
  grid-area: city;
`;
const EmailAddress = styled(SearchInput)``;

const FleetEmp = styled.div`
  grid-area: fleetemp;
`;
const FleetNumber = styled(ShortInput)`
  width: calc(50% - 8px);
  margin-right: 8px;
`;
const EmployeeID = styled(ShortInput)`
  width: calc(50% - 8px);
  margin-left: 8px;
`;
const CustomerSearchButton = styled(SearchButton)``;
export const CustomerCreateButton = styled(SearchButton)`
  grid-area: create;
  background-color: ${theme.colors.gray};
  color: ${theme.colors.obsidian};
`;

export const RequiredContainer = styled.div`
  display: flex;
  position: absolute;
  left: 330px;
  top: -30px;
  ${({ left }) => left && `left:${left}`}
`;
export const RequiredFields = styled.div`
  font-size: 14px;
  color: ${theme.colors.titleBlack};
`;
const CustomerForm = (props) => {
  const {
    detailTitle, titleButtonName, formButtonName, isCreate
  } = props;
  const {
    setSearchOrigin,
    setModuleName,
    setSearchQuery,
    searchActive,
    setSearchActive,
    customerFields,
    setCustomerFields,
    setIsModalOn,
    setModalDetails,
    errorMessageObj,
    setErrorMessageObj,
    setCustomerInfo,
    setCSRSelected,
    setVehSelected,
    setLoader,
    setApplicationError,
  } = useContext(CVMContext);
  const { getStateOptions, getStateOrProvince } = useContext(APIContext);
  const [notFound, setNotFound] = useState(false);
  const [requiredMet, setRequiredMet] = useState(false);
  const [isSRPMIC, setIsSRPMIC] = useState(false);
  const [srpmicOpen, setSRPMICOpen] = useState(false);
  // Field Error object
  const [fieldErrors, setFieldErrors] = useState({});
  useEffect(() => {
    logger('SearchByCustomer', 'useEffect', 'isCreate', 'customerFields.zip');

    const firstNameEl = document.querySelector('[name="firstName"]');

    if (!isCreate) {
      document.getElementById('phone')?.focus();
      setFieldErrors({});
    } else {
      firstNameEl.focus();
    }

    // Check if zip element is focused


    if (isSRPMICSite(localStorage.getItem('siteId'))) {
      setIsSRPMIC(true);
    }
  }, [isCreate]);

  useEffect(() => {
    if (isCreate) { fetchZip(customerFields, setCustomerFields, setLoader, getStateOptions); }

  }, [customerFields.zip]);

  useEffect(() => {
    logger('SearchByCustomer', 'useEffect',
      'isCreate', 'searchActive', 'setSearchActive', 'customerFields',
      'setCustomerFields', 'customerFields.country', 'errorMessageObj.message');

    if (errorMessageObj.message === constants.NO_CUSTOMER_FOUND) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
    const res = checkIfAnyFieldIsFilled(customerFields);
    if (typeof customerFields.country === 'string') {
      setCustomerFields({
        ...customerFields,
        country: countryOptions.filter(
          (option) => option.label === customerFields.country
        )[0],
      });
    }
    if (!searchActive) {
      setFieldErrors(constants.EMPTY_STRING);
    }
    if (res.length > 0) {
      setSearchActive(true);
    } else {
      if (errorMessageObj.message === constants.NO_CUSTOMER_FOUND) {
        setErrorMessageObj({});
      }
      setSearchActive(false);
    }
    // check required fields only if isCreate form
    if (isCreate) {
      // commenting out below line due to bug-CAVA 209, create should always pass default phonetype.
      // !isNullOrUndefined(customerFields?.phoneType) &&
      checkForEmptyObject(customerFields.phoneType) &&
        setCustomerFields({
          ...customerFields,
          phoneType: phoneTypeOptions[0],
        });
      const isValidForm = checkIfAnyFieldIsFilled(customerFields);
      const checkFields = checkRequiredFields(customerFields, constants.MODULE_CREATE_CUSTOMER);
      !isCreate ? isEmptyArray(isValidForm) ? setRequiredMet(true) : setRequiredMet(false) : checkFields ? setRequiredMet(true) : setRequiredMet(false);
    }
  }, [
    isCreate,
    searchActive,
    setSearchActive,
    customerFields,
    setCustomerFields,
    customerFields.country,
    errorMessageObj.message,
  ]);
  const updateDropdown = (value, fieldName) => {
    let item;
    const updatedCustomerFields = { ...customerFields };
    switch (fieldName) {
      case 'phoneCountryCode':
        item = [value];
        updatedCustomerFields.phoneNumber = constants.EMPTY_STRING;
        break;
      case 'phoneType':
        item = phoneOptions.filter((option) => option.label === value.label);
        break;
      case 'country':
        item = countryOptions.filter((option) => option.label === value.label);
        if (value.value !== customerFields.country?.value) {
          updatedCustomerFields.zip = constants.EMPTY_STRING;
          updatedCustomerFields.state = constants.EMPTY_STRING;
          updatedCustomerFields.city = constants.EMPTY_STRING;
        }
        break;
      case 'state':
        item = getStateOrProvince(customerFields?.country?.value, value);
        break;
      default:
        return null;
    }
    if (value && fieldName && item) {
      setCustomerFields({ ...updatedCustomerFields, [fieldName]: item[0] });
    }
  };

  const closeState = () => {
    setCustomerFields({ ...customerFields, state: constants.EMPTY_STRING });
  };

  const stateDropdownProps = {
    fieldName: 'state',
    placeholder: getStateOrProvincePlaceholder(customerFields?.country?.value),
    options: getStateOptions(customerFields?.country?.value),
    onChange: updateDropdown,
    selectedOption: customerFields.state,
    value: customerFields.state,
    required: isCreate,
    isCreate,
    positionStyle: 'absolute',
    dropdownHeight: 250,
    onClickClear: closeState,
  };

  const phoneTypeDropdownProps = {
    fieldName: 'phoneType',
    placeholder: 'Phone Type',
    options: phoneOptions,
    onChange: updateDropdown,
    selectedOption: customerFields?.phoneType,
    value: customerFields.phoneType?.value,
    required: isCreate,
    positionStyle: 'absolute',
  };

  const phoneCountryDropdown = {
    fieldName: 'phoneCountryCode',
    onChange: updateDropdown,
    selectedOption: customerFields.phoneCountryCode,
    value: customerFields.phoneCountryCode,
  };

  const countryDropdownProps = {
    fieldName: 'country',
    placeholder: 'Country',
    options: countryOptions,
    onChange: updateDropdown,
    selectedOption: customerFields?.country,
    value: customerFields.country?.value,
    required: isCreate,
    positionStyle: 'absolute',
  };

  const handleInput = async (value, fieldName) => {
    const fieldValue = customerFormValidation(value, fieldName, customerFields.country?.value);
    setCustomerFields({ ...customerFields, [fieldName]: fieldValue });
  };

  const customerCreateModule = () => {
    setErrorMessageObj({});
    setCSRSelected(null);
    setVehSelected(null);
    setModuleName(constants.MODULE_CREATE_CUSTOMER);
  };

  const onSubmitAddress = async (address, customer) => {
    Object.assign(customer, address);
    setIsModalOn(false);
    try {
      const formattedCustomer = formatCustomerRequest({ ...customer });
      const res = await APIRouter(
        'C360',
        'addCustomer',
        formattedCustomer,
        setLoader,
        false,
        setApplicationError,
      );

      if (!res?.customer.customerId) return;
      setCSRSelected({ id: res.customer.customerId });
      setCustomerInfo({ SRPMICNumber: customerFields.SRPMICNumber });
      setSearchOrigin(constants.SEARCH_ORIGIN_CREATE_CUSTOMER);
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      if (localStorage.getItem('SRPMIC') === 'true') {
        localStorage.setItem('SRPMICNumber', `${res.customer.customerId}:${customerFields.SRPMICNumber}`);
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  };
  const isDisabled = isCreate ? !requiredMet : !searchActive;

  const createNewCustomer = async (e) => {
    e.preventDefault();
    const fieldError = editFieldLengthMet(customerFields);
    setFieldErrors(fieldError);
    if (!isEmptyObject(fieldError)) return;
    const custFields = { ...customerFields };
    const data = await addressVerification(customerFields, setLoader, true);
    if (data.length === 1) {
      onSubmitAddress(data[0], custFields);
    } else {
      data.onSubmit = (value) => {
        onSubmitAddress(value, custFields);
      };
      setModalDetails(data);
      setIsModalOn(true);
    }
  };
  const searchByCustomer = (e) => {
    e.preventDefault();
    setErrorMessageObj({});
    const fieldError = validateCustomerOnSearch(customerFields);
    setFieldErrors(fieldError);
    if (formErrorControl(customerFields)) {
      setErrorMessageObj({
        message: `Enter ${formErrorControl(
          customerFields
        )} to refine your search`,
        searchBy: constants.SEARCH_ORIGIN_CUSTOMER,
        type: constants.WARNING,
      });
    } else if (searchActive && isEmptyObject(fieldError)) {
      // convert country prop from obj to value
      if (!customerFields.country) {
        customerFields.country = {};
        customerFields.country.label = constants.EMPTY_STRING;
      }
      customerFields.country = customerFields.country.label;

      if (!customerFields.state) {
        customerFields.state = {};
        customerFields.state.value = constants.EMPTY_STRING;
      }
      const searchFields = {
        ...customerFields,
        state: customerFields.state.value,
        city: customerFields.city,
        zip: customerFields.zip,
        address1: customerFields.address1,
        address2: customerFields.address2,
        phoneNumber: customerFields.phoneNumber,
      };
      setSearchQuery(searchFields);
      setSearchOrigin(constants.SEARCH_ORIGIN_CUSTOMER);
    }
  };
  const renderZipCode = () => {
    return (
      <ZipCode
        type={constants.TEXT}
        fieldName="zip"
        inputValue={customerFields.zip}
        label={getZipCodeLabel(customerFields?.country?.value, true)}
        errorMessage={fieldErrors?.zip}
        inputHandler={handleInput}
        isDisabled={false}
        tabIndex={isCreate ? 8 : 9}
        required={isCreate}
        isCreate={isCreate}
        maxLength={customerFields?.country?.label === constants.UNITED_STATES ? 5 : 10}
      />
    );
  };

  const renderCity = () => {
    const CityElement = isCreate ? CityNameCreate : CityName;
    return (
      <CityElement
        type={constants.TEXT}
        fieldName="city"
        inputValue={customerFields.city}
        label="City"
        errorMessage={fieldErrors?.city}
        warningMessages={[]}
        inputHandler={handleInput}
        isDisabled={false}
        tabIndex={isCreate ? 9 : 9}
        required={isCreate}
        maxLength={40}
      />
    );
  };

  const renderSRPMIC = () => {
    if (srpmicOpen) {
      setSRPMICOpen(false);
    } else {
      setSRPMICOpen(true);
    }
    setCustomerFields({
      ...customerFields,
      SRPMICNumber: constants.EMPTY_STRING,
    });
  };
  return (
    <form>
      <DetailSection title={detailTitle}>
        {isCreate && (
          <RequiredContainer>
            <RequiredSpan required>*</RequiredSpan>
            <RequiredFields>{constants.TITLE_REQUIRED_FIELDS}</RequiredFields>
          </RequiredContainer>
        )}
        {errorMessageObj.searchBy === constants.SEARCH_ORIGIN_CUSTOMER && (
          <Message
            errorMessages={errorMessageObj.message}
            type={errorMessageObj.type}
          />
        )}
        <DetailGrid isCreate={isCreate} fieldErrors={fieldErrors}>
          <NameInputs>
            <FirstName
              fieldName="firstName"
              inputValue={customerFields.firstName}
              label="First Name"
              errorMessage={fieldErrors?.firstName && isCreate ? fieldErrors?.firstName : fieldErrors?.firstName && []}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={isCreate ? 1 : 5}
              required={isCreate}
              maxLength={40}
            />
            <LastName
              type={constants.TEXT}
              fieldName="lastName"
              inputValue={customerFields.lastName}
              label="Last Name"
              errorMessage={fieldErrors?.lastName && isCreate ? fieldErrors?.lastName : fieldErrors.lastName && []}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={isCreate ? 2 : 6}
              required={isCreate}
              maxLength={40}
            />
            {!isCreate && (fieldErrors?.firstName || fieldErrors?.lastName) && (
              <Message
                errorMessages={fieldErrors?.firstName || fieldErrors?.firstName}
                type={constants.ERROR}
              />
            )}
          </NameInputs>
          <PhoneNumberContainer>
            {!isCreate ? (
              <PhoneNumber
                type={constants.TELEPHONE}
                fieldName="phoneNumber"
                inputValue={customerFields.phoneNumber}
                label="Phone Number"
                errorMessage={fieldErrors?.phoneNumber}
                inputHandler={handleInput}
                isDisabled={false}
                tabIndex={isCreate ? 3 : 3}
                isCreate={isCreate}
                required={isCreate}
              />
            ) : (
              <PhoneCountryDropdown
                inputValue={customerFields.phoneNumber}
                label="Phone Number"
                inputHandler={handleInput}
                isDisabled={false}
                tabIndex={isCreate ? 3 : 3}
                isCreate={isCreate}
                required={isCreate}
                fieldErrors={fieldErrors}
                errorMessage={fieldErrors?.phoneNumber}
                countryDropdown={phoneCountryDropdown}
              />
            )}
          </PhoneNumberContainer>
          {isCreate && (
            <PhoneType
              tabIndex={isCreate ? 4 : null}
              {...phoneTypeDropdownProps}
            />
          )}
          <EmailAddressContainer>
            <EmailAddress
              type={constants.TEXT}
              required={false}
              fieldName="email"
              errorMessage={fieldErrors?.email}
              inputValue={customerFields.email}
              label="Email Address"
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={isCreate ? 5 : 4}
              maxLength={50}
            />
          </EmailAddressContainer>
          <AddressLine1
            type={constants.TEXT}
            fieldName="address1"
            inputValue={customerFields.address1}
            label="Address Line 1"
            errorMessage={fieldErrors?.address1}
            inputHandler={handleInput}
            isDisabled={false}
            tabIndex={isCreate ? 6 : 7}
            required={isCreate}
            maxLength={60}
          />
          {isCreate && renderZipCode()}
          {isCreate && (
            <StateCountry fieldName="state"
              errorMessage={fieldErrors?.state}
            >
              <StateProvince
                fieldName="state"
                tabIndex={isCreate ? 10 : 10}
                errorMessage={fieldErrors?.state}
                {...stateDropdownProps}
              />
              <Country
                tabIndex={isCreate ? 11 : 12}
                {...countryDropdownProps}
              />
            </StateCountry>
          )}
          <CompanyName
            type={constants.TEXT}
            required={false}
            fieldName="companyName"
            inputValue={customerFields.companyName}
            label="Company Name"
            inputHandler={handleInput}
            errorMessage={fieldErrors?.companyName}
            isDisabled={false}
            tabIndex={isCreate ? 12 : 13}
            maxLength={35}
          />
          <AddressOptional
            type={constants.TEXT}
            required={false}
            fieldName="address2"
            inputValue={customerFields.address2}
            label="Address Line 2 (optional)"
            inputHandler={handleInput}
            isDisabled={false}
            tabIndex={isCreate ? 7 : 8}
            maxLength={40}
          />
          {isCreate && (
            <SRPMICMilitaryArea>
              {isCreate && (
                <MilitaryWrapper isCreate >
                  <EditCheckboxWrapper
                    onClick={() => handleInput(!customerFields?.isMilitary, 'isMilitary')}
                  >
                    <MilitaryCheckbox
                      checked={customerFields?.isMilitary}
                    />
                    <MilitaryLabel >
                      {constants.MILITARY_FLAG}
                    </MilitaryLabel>
                  </EditCheckboxWrapper>
                </MilitaryWrapper>
              )}
              {isCreate && isSRPMIC && (
                <SRPMICWrapper isClosed={!srpmicOpen}>
                  <CheckboxWrapper
                    tabIndex={isCreate && 15}
                    onClick={renderSRPMIC}
                  >
                    <SRPMICCheckbox checked={srpmicOpen} />
                    <SRPMICLabel>{constants.SRPMIC_LABEL}</SRPMICLabel>
                  </CheckboxWrapper>
                  {srpmicOpen && (
                    <SRPMICInput
                      type={constants.TEXT}
                      required={false}
                      fieldName="SRPMICNumber"
                      inputValue={customerFields.SRPMICNumber}
                      label="SRPMIC #"
                      inputHandler={handleInput}
                      isDisabled={false}
                      tabIndex={isCreate && 16}
                      maxLength={20}
                    />
                  )}
                </SRPMICWrapper>
              )}
            </SRPMICMilitaryArea>
          )}
          {!isCreate && (
            <CityState>
              {renderCity()}
              <StateProvince
                tabIndex={isCreate ? 9 : 9}
                {...stateDropdownProps}
              />
            </CityState>
          )}
          {!isCreate && (
            <ZipCountry>
              {renderZipCode()}
              <Country
                tabIndex={isCreate ? 11 : 11}
                {...countryDropdownProps}
              />
            </ZipCountry>
          )}
          {isCreate && renderCity()}
          {!isCreate && (
            <FleetEmp>
              <FleetNumber
                type={constants.TEXT}
                required={false}
                fieldName="fleetNumber"
                inputValue={customerFields.fleetNumber}
                errorMessage={fieldErrors.fleetNumber}
                label="Fleet #"
                inputHandler={handleInput}
                isDisabled={false}
                maxLength={5}
                tabIndex={isCreate ? null : 14}
              />
              <EmployeeID
                type={constants.TEXT}
                required={false}
                fieldName="employeeID"
                inputValue={customerFields.employeeID}
                errorMessage={fieldErrors?.employeeID}
                label="DT Employee ID"
                inputHandler={handleInput}
                isDisabled={false}
                maxLength={6}
                tabIndex={isCreate ? null : 15}
              />
            </FleetEmp>
          )}
          <CustomerSearchButton
            buttonName={formButtonName}
            tabIndex={15 || null}
            isDisabled={isCreate ? !requiredMet : !searchActive}
            onSubmit={isDisabled ? (e) => { e.preventDefault(); } : (isCreate ? createNewCustomer : searchByCustomer)}
            onButtonClick={isDisabled ? (e) => { e.preventDefault(); } : (isCreate ? createNewCustomer : searchByCustomer)}
          />
          {notFound && (
            <CustomerCreateButton
              buttonName={titleButtonName}
              onButtonClick={customerCreateModule}
              isCreate
            />
          )}
        </DetailGrid>
      </DetailSection>
    </form>
  );
};

export default CustomerForm;
