import { OktaAuth } from '@okta/okta-auth-js';

import env from '@beam-australia/react-env';
import API from 'dt-cvm-api';
import debug from 'debug';

const Okta = {

  _logger: debug('api:okta'),

  _trace: API.utils.isTruthy(env('API_LOG_TRACE')) ?
    debug('api:okta:trace') : null,

  _verbose: API.utils.isTruthy(env('API_LOG_VERBOSE')) ?
    debug('api:okta:verbose') : null,

  async shutItDown(oktaConfig, message = 'okta error, resetting.') {
    Okta._logger(message);

    const authClient = new OktaAuth(
      oktaConfig
    );

    await authClient.revokeAccessToken();

    return authClient.closeSession()
      .then((sessionClosed) => {
        if (sessionClosed) {
          window.location = '/';
        } else {
          // Session does not exist or has already been closed
          throw new Error('Session didn\'t close');
        }
      });
  },

	async initialize(oktaConfig, callback) {
  	const startup = async () => {
			try {
				await callback();
			}	catch (e) {
        Okta._trace && Okta._trace('Error calling startup callback');
        Okta._verbose && Okta._verbose(e);
			}
		};

    Okta._logger('initializing client');

  	// create OktaAuth instance
  	const authClient = new OktaAuth(oktaConfig);

  	// Subscribe to authState change event.
  	authClient.authStateManager.subscribe(async (authState) => {

      Okta._trace && Okta._trace('authState changed');
      Okta._verbose && Okta._verbose(authState);

    	// Logic based on authState is done here.
    	if (!authState.isAuthenticated) {
      	// render unathenticated view
        Okta._logger('unauthenticated... running sign in');

        // TODO: if no prior... sign in, otherwise renew ?
        return authClient.signInWithRedirect();
    	}

      // We're authenticated... should we start up? Only if no prior auth state
      // which means we're doing this for the first time.
      const previousAuthState = authClient.authStateManager.getPreviousAuthState();
      if (!previousAuthState) {
        Okta._logger('did authenticate... running startup');

    	  // Render authenticated view
			  await startup();
      } else {
        Okta._trace && Okta._trace('authState re-authenticated');
      }
  	});

  	// Handle callback
  	if (authClient.token.isLoginRedirect()) {
      Okta._trace && Okta._trace('handling redirect');

			try {
    		const { tokens } = await authClient.token.parseFromUrl();
    		authClient.tokenManager.setTokens(tokens);

				Okta._trace && Okta._trace('tokens acquired');
			} catch (e) {
        // Common error with browser reloads is PKCE codeVerifier load glitch...
        Okta._logger('error parsing url tokens');

        // If it's the PKCE thing don't bother logging it, it's "expected".
        if (!/PKCE codeVerifier/.test(e?.message)) {
          Okta._verbose && Okta._verbose(e);
        }

      	const tokenJSON = globalThis.sessionStorage &&
        	globalThis.sessionStorage.getItem('okta-token-storage');
				if (!tokenJSON) {
        	return authClient.signInWithRedirect();
				}

        let storedTokens;
      	try {
        	storedTokens = JSON.parse(tokenJSON);
        } catch (e) {
          // Ignore any parse errors, we'll just let the fallthrough logic below
          // redirect to sign in.
        }

        if (storedTokens?.idToken) {
          try {
          	await authClient.token.verify(storedTokens?.idToken);

          	Okta._trace && Okta._trace('reusing stored tokens');
						await startup();
						return;
          } catch (e) {
            try {
              // Common case here is JWT expired... try to refresh if we can.
          	  storedTokens = await authClient.token.renew(tokens?.idToken);
    		      authClient.tokenManager.setTokens(storedTokens);
            } catch (e2) {
              // If we can't refresh, just fall through to redirect to sign in.
              Okta._logger('error renewing tokens');
            }
          }
        }

        Okta._trace && Okta._trace('retrying sign in');
        return authClient.signInWithRedirect();
			}
  	}

    Okta._logger('starting service');

  	// will update auth state and call event listeners
  	await authClient.start();
	},

};

export default Okta;
