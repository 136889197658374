import * as constants from '../constants/Constants';

const getModalData = (modalName, moduleName, callBack) => {
  switch (modalName) {
    case constants.CLEAR_SEARCH_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CLEAR_SEARCH_MODAL_TITLE,
        subtitle: constants.CLEAR_SEARCH_MODAL_SUBTITLE,
        cancelButton: constants.CLEAR_SEARCH_MODAL_CANCEL_BUTTON,
        confirmButton: constants.CLEAR_SEARCH_MODAL_CONFIRM_BUTTON,
      };
    case constants.MODAL_TYPE_CLEAR_EDIT:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CLEAR_EDIT_MODAL_TITLE,
        subtitle: constants.CLEAR_EDIT_SUBTITLE,
        cancelButton: constants.CLEAR_EDIT_CANCEL_BUTTON,
        confirmButton: constants.CLEAR_EDIT_CONFIRM_BUTTON,
      };
    case constants.NEW_SEARCH_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.NEW_SEARCH_MODAL_TITLE,
        subtitle: constants.NEW_SEARCH_MODAL_SUBTITLE,
        cancelButton: moduleName === constants.MODULE_CUSTOMER_SUMMARY ? constants.TO_CUSTOMER_SUMMARY :  constants.NEW_SEARCH_MODAL_CANCEL_BUTTON,
        confirmButton: constants.NEW_SEARCH_MODAL_CONFIRM_BUTTON,
      };
    case constants.NO_CUSTOMER_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.NO_CUSTOMER_MODAL_TITLE,
        subtitle: constants.NO_CUSTOMER_MODAL_SUBTITLE,
        cancelButton: constants.NO_CUSTOMER_MODAL_CANCEL_BUTTON,
        confirmButton: constants.NO_CUSTOMER_MODAL_CONFIRM_BUTTON,
      };
    case constants.CUSTOMER_BACK_TO_SEARCH_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CUSTOMER_BACK_TO_SEARCH_MODAL_TITLE,
        cancelButton: constants.CUSTOMER_BACK_TO_SEARCH_MODAL_CANCEL_BUTTON,
        confirmButton: constants.CUSTOMER_BACK_TO_SEARCH_MODAL_CONFIRM_BUTTON,
      };
    case constants.MODAL_TYPE_SUMMARY:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CONTINUE_WITHOUT_SAVING,
        subtitle: constants.CHANGES_NO_SAVE,
        cancelButton: constants.CONTINUE_EDITING,
        confirmButton: constants.BACK_TO_CUSTOMER_SUMMARY_NO_SAVE,
      };
    case constants.MODAL_TYPE_REACTIVATE_VEHICLE:
      return {
        type: constants.MODAL_TYPE_REACTIVATE_VEHICLE,
        title: constants.REACTIVATE_VEHICLE_MODAL_TITLE,
        cancelButton: constants.REACTIVATE_VEHICLE_MODAL_CANCEL_BUTTON,
        confirmButton: constants.REACTIVATE_VEHICLE_MODAL_CONFIRM_BUTTON,
      };
    case constants.MODAL_TYPE_ADDRESS:
      return {
        type: constants.RADIO_FORM_ADDRESS_VERIFY,
        title: constants.ADDRESS_VERIFICATION_MODAL,
        subtitle: constants.ADDRESS_VERIFICATION_MODAL_SUBTITLE,
      };
    case constants.MODAL_TYPE_ONE_ADDRESS:
      return {
        type: constants.RADIO_FORM_ADDRESS_ONE_OPTION,
        title: constants.ADDRESS_VERIFICATION_MODAL,
      };
    case constants.MODAL_OVERRIDE_SELECTED_VEHICLE:
      return {
        modalName,
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.MODAL_OVERRIDE_SELECTED_VEHICLE_TITLE,
        subtitle: constants.MODAL_OVERRIDE_SELECTED_VEHICLE_SUBTITLE_VTV,
        cancelButton: constants.MODAL_OVERRIDE_SELECTED_VEHICLE_BUTTON,
        confirmButton: constants.MODAL_OVERRIDE_INSPECTION_VEHICLE_BUTTON,
      };
    case constants.CUSTOMER_EDIT_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CUSTOMER_EDIT_MODAL_TITLE,
        subtitle: constants.CUSTOMER_EDIT_MODAL_SUBTITLE,
        cancelButton: constants.CUSTOMER_EDIT_MODAL_CANCEL_BUTTON,
        confirmButton: constants.CUSTOMER_EDIT_MODAL_CONFIRM_BUTTON,
      };
    case constants.CUSTOMER_NOT_FOUND_INSPECTION:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.CUSTOMER_NOT_FOUND_TITLE,
        subtitle: constants.CUSTOMER_NOT_FOUND_SUBTITLE,
        cancelButton: constants.CUSTOMER_NOT_FOUND_CANCEL_BUTTON,
        confirmButton: constants.CUSTOMER_NOT_FOUND_CONFIRM_BUTTON,
      };
    case constants.HOLDING_INSPECTION_MODAL:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.HOLDING_INSPECTION_MODAL_TITLE,
        subtitle: constants.HOLDING_INSPECTION_MODAL_SUBTITLE,
        cancelButton: constants.HOLDING_INSPECTION_MODAL_CANCEL_BUTTON,
        confirmButton: constants.HOLDING_INSPECTION_MODAL_CONFIRM_BUTTON,
      };
    case constants.IMPORTED_VEHICLE_MODAL:
      return {
        type: constants.IMPORTED_VEHICLE_MODAL,
        title: constants.IMPORTED_VEHICLE_MODAL_TITLE,
        subtitle: constants.IMPORTED_VEHICLE_MODAL_SUBTITLE,
        cancelButton: constants.IMPORTED_VEHICLE_MODAL_CANCEL_BUTTON,
        confirmButton: constants.IMPORTED_VEHICLE_MODAL_CONFIRM_BUTTON,
        callBack,
      };
    default:
      return null;
  }
};
export const getErrorModalData = (errorName) => {
  switch (errorName) {
    case constants.VEHICLE_ADD_ERROR:
      return {
        type: constants.MODAL_TYPE_SEARCH,
        title: constants.VEHICLE_ADD_ERROR_TITLE,
        subtitle: constants.VEHICLE_ADD_ERROR_SUBTITLE,
        cancelButton: constants.CLEAR_SEARCH_MODAL_CANCEL_BUTTON,
        confirmButton: constants.CLEAR_SEARCH_MODAL_CONFIRM_BUTTON,
      };
    default:
      return null;
  }
};

export default getModalData;
