import moment from 'moment';
import {
  checkIfObjectHasKey,
  formatAlphaNumeric,
  formatAlphaNumericAndSpace,
  formatNumeric, formatNumericDecimal, formatNumericOneDecimal, formatOnlyOneZero, isEmptyArray, isNullEmptyUndefined,
} from './validationHelper';
import * as constants from '../constants/Constants';
import { APIRouter } from '../Data/APIRouter';
import { deepObjectCopy } from './CustomerHelper';
import formatMileage, { getAutoSyncData, vtvAircheckAddVehicle } from '../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';

export const formatTireWidthInput = (value) => {
  const inputValue = formatOnlyOneZero(formatNumericDecimal(value));
  if (inputValue < 0) return 0;
  if (inputValue > 950) return 950;
  return inputValue;
};

export const formatTireWidth = (value) => {
  let inputValue = formatOnlyOneZero(formatNumericOneDecimal(value));
  inputValue =  parseFloat(inputValue, 10).toFixed(1);
  if (Number.isNaN(inputValue)) return;
  if (inputValue < 2) return 2;
  if (inputValue > 950) return 950;
  if (inputValue.isNaN) return;
  return inputValue;
};
export const formatTireRatio = (value) => {
  let inputValue = formatOnlyOneZero(formatNumericOneDecimal(value));
  inputValue = parseFloat(inputValue, 10).toFixed(1);
  if (Number.isNaN(inputValue)) return;
  if (inputValue > 790) return 790;
  if (inputValue.isNaN) return;
  return inputValue;
};
export const formatTireDiameter = (value) => {
  let inputValue = formatOnlyOneZero(formatNumericOneDecimal(value));
  inputValue =  parseFloat(inputValue, 10).toFixed(1);
  if (Number.isNaN(inputValue)) return;
  if (inputValue < 3) return 3;
  if (inputValue > 540) return 540;
  return inputValue;
};
export const formatTirePressure = (value) => {
  const inputValue = formatOnlyOneZero(formatNumeric(value));
  if (inputValue > 125) return inputValue.replace(inputValue, 125);
  return inputValue;
};
export const formatTorque = (value) => {
  const inputValue = formatOnlyOneZero(formatNumeric(value));
  if (inputValue > 600) return inputValue.replace(inputValue, 600);
  return inputValue;
};

export const formatBoltPattern = (value) => {
  const inputValue = (formatOnlyOneZero(value?.replace(/[^ 0-9.-]/g, constants.EMPTY_STRING)));
  return inputValue;
};

export const formatLugNutPattern = (value) => {
  const inputValue = formatOnlyOneZero(value?.replace(/[^ 0-9.x/]/g, constants.EMPTY_STRING));
  return inputValue;
};

export const removeEmptyDecimal = (value) => {
  let inputValue = value;
  if (value?.length) {
    inputValue = value?.split('.');
    if (inputValue.length === 2) {
      if (inputValue[1] === constants.EMPTY_STRING) {
        inputValue = inputValue[0];
      } else {
        inputValue = `${inputValue[0]}.${inputValue[1]}`;
      }
    } else {
      inputValue = value;
    }
  }
  return inputValue;
};

export const formatBolt = (value) => {
  if (!value) return;
  const inputValue = value.replace(/[^0-9.-]/g, constants.EMPTY_STRING);
  if (value > 10) return '10 - 285.00';
  let [boltValue, circleValue] = inputValue.split('-');
  if (boltValue < 1) {
    boltValue = 1;
  }
  if (circleValue < 1) {
    circleValue = 1;
  }

  if (boltValue > 10) boltValue = 10;
  if (circleValue > 285) circleValue = 285;
  circleValue = circleValue ? Number(circleValue).toFixed(2) : constants.EMPTY_STRING;
  return `${boltValue}${circleValue ? ` - ${circleValue}` : constants.EMPTY_STRING}`;
};

export const formatLugNut = (value) => {
  if (!value) return;
  const inputValue = value.replace(/[^0-9.x/]/g, constants.EMPTY_STRING);
  if (value < 1) return '1 x 1.25';
  if (value > 22) return '22 x 16.00';
  let [diameterValue, threadPitchValue] = inputValue.includes('x')
    ? inputValue.split('x')
    : (inputValue.includes('/') ? inputValue.split('/') : constants.EMPTY_STRING);
  diameterValue = Number(diameterValue).toFixed();
  if (diameterValue < 1 || threadPitchValue < 1.25) {
    diameterValue = 1;
    threadPitchValue = 1.25;
  }
  if (diameterValue > 22)diameterValue = 22;
  return inputValue.includes('x')
    ? `${diameterValue} x ${threadPitchValue}`
    : (inputValue.includes('/') ? `${diameterValue} / ${threadPitchValue}` : inputValue);
};

export const vehicleFormValidation = (value, fieldName) => {
  switch (fieldName) {
    case 'make':
    case 'model':
    case 'trim':
      return formatAlphaNumericAndSpace(value);
    case 'vin':
    case 'licensePlate':
      return formatAlphaNumeric(value).toUpperCase();
    case 'tireWidth':
    case 'rearTireWidth':
      return formatTireWidth(value);
    case 'wheelWidth':
    case 'rearWheelWidth':
      return formatTireWidthInput(value);
    case 'tireRatio':
    case 'rearTireRatio':
      return formatTireRatio(value);
    case 'tireDiameter':
    case 'rearTireDiameter':
      return formatTireDiameter(value);
    case 'tirePressure':
    case 'rearTirePressure':
      return formatTirePressure(value);
    case 'wheelTorque':
    case 'rearWheelTorque':
      return formatTorque(value);
    case 'wheelBoltPattern':
    case 'rearWheelBoltPattern':
      return formatBoltPattern(value);
    case 'wheelLugNutSize':
    case 'rearWheelLugNutSize':
      return formatLugNutPattern(value);
    case 'notes':
      return value;
    default:
      return formatNumeric(value);
  }
};

export const genericVehicleFieldValidations = (assemblyConfiguration,
  tireWidth, tireRatio, tireDiameter,
  rearTireWidth, rearTireRatio, rearTireDiameter) => {
  let requirementsMet = false;
  if (assemblyConfiguration === constants.ASSEMBLY_VALUES[0].value
    || assemblyConfiguration === constants.ASSEMBLY_VALUES[1].value
  ) {
    requirementsMet = [tireWidth, tireRatio, tireDiameter].every(
      (field) => !isNullEmptyUndefined(field),
    );
  }
  if (assemblyConfiguration === constants.ASSEMBLY_VALUES[2].value) {
    requirementsMet = [
      tireWidth, tireRatio, tireDiameter,
      rearTireWidth, rearTireRatio, rearTireDiameter].every(
      (field) => !isNullEmptyUndefined(field),
    );
  }
  return requirementsMet;
};

// No year make model checker

export const getVehicleInfo = async (vehicle, setLoader) => {
  if (vehicle?.trim)vehicle.trim = vehicle?.trim?.replaceAll('\\', '');
  const vehicleInfo = { ...vehicle };
  let {
    year, overrideYear, make, overrideMake, model, overrideModel, trim, trimId, trimName, overrideTrim, overrideVehicleCategory, overrideVehicleSubCategory
  } = vehicleInfo;
  const isGeneric = isGenericVehicle(vehicle);
  if (isGeneric) {
    year = overrideYear;
    make = overrideMake;
    model = overrideModel;
    trim = overrideTrim;
  }
  const tireTemplate = {
    vehicleTireData: [{
      wheels: '',
      wheelSize: '',
      factoryTireSize: '',
      frontTirePressure: '',
      rearTirePressure: '',
      boltPattern: '',
      lugNutSize: '',
      torque: '',
      wheelWidthOffset: '',
      tireEquipment: constants.VEHICLE_OE_LABEL,
      isOE: true,
    }]
  };

  let assemblies,
    assemblyCode,
    assembly,
    tireDetails,
    trims;
  if (year && make && model && (trimId || trim || trimName) && !isGeneric) {
    try {

      // load autoSync data if applicable
      const autoSync = await getAutoSyncData(vehicle, setLoader);

      // grab the autosync colors if autosync available
      let autoSyncData;
      if (autoSync && autoSync?.length > 0) {
        const autoSyncColor = autoSync.find(ac => ac.Image === vehicle.imageUrl);
        autoSyncData = {
          autoSync,
          autoSyncColor: autoSyncColor ? { value: autoSyncColor.Name, label: autoSyncColor.Name } : undefined,
        };
      }

      vehicle.autoSyncData = autoSyncData;

      assemblies = await APIRouter('FIT', 'getVehicleAssemblies', {
        // Added the vtv check because the ids in assembly fetch doenst match
        year, make, model, trim: vehicleInfo?.trimId || trim || trimName,
      }, setLoader);

      // re-attempt to get assemblies with trim if trimId doesn't match
      if (!assemblies) {
        assemblies = await APIRouter('FIT', 'getVehicleAssemblies', {
          // Added the vtv check because the ids in assembly fetch doenst match
          year, make, model, trim: trim || trimName,
        }, setLoader);
      }

      trims = await APIRouter('FIT', 'getVehicleTrims', ({ year, make, model }), setLoader, true);
      const trimLiftId = trims?.vehicleTrim?.liftCollection.filter(t => t.liftItem.chassisId === vehicle.chassisCode)[0]?.liftItem?.liftIdCollection?.filter(lc => lc.liftIdItem.liftDescription === vehicle.fitmentLiftedId)[0]?.liftIdItem?.liftId ?? null;
      vehicle.trimLiftId = trimLiftId;
    } catch (e) {
      // Error handling modals
      console.error(e);
    }
    //  Compute the assemblyCode
    if (vehicleInfo.assemblyCollection?.filter((item) => item.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE).length > 0) {
      assemblyCode = vehicleInfo.assemblyCollection?.filter((item) => item.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE)[0].assemblyItem.assemblyCode;
    } else if (vehicleInfo.assemblyCollection?.filter((item) => item.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE).length > 0) {
      assemblyCode = vehicleInfo.assemblyCollection?.filter((item) => item.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE)[0].assemblyItem.assemblyCode;
    } else {
      assemblyCode = null;
    }

    assembly = assemblies?.filter((item) => {
      return item.assemblyId === assemblyCode;
    })[0];

    if (!assembly) {
      assembly = assemblies ? assemblies[0] : constants.NOT_APPLICABLE;
    }
    //  NB: The setters here modify vehicle - otherwise we'd be instrumenting a
    //  copy.
    if (assembly && assembly !== constants.NOT_APPLICABLE) {
      vehicle.currentAssembly = assembly;
      vehicle.assemblyId = assemblyCode || assembly.assemblyId;
      vehicle.trimId = assembly.trimId;
      vehicle.fitmentVehicleId = assembly.fitmentId;
      vehicle.fitmentVehicleClass = assembly.fitmentClass;
      vehicle.chassisCode = assembly.chassisId;
    } else {
      // don't really have to do anything if we can't access fitment services since the data should already be attached to the vehicle obj, but just leaving this here in case
    }
    //  NOTE we have to switch to trimId if possible since the original coding
    //  uses 'trim' as the 'trim description' rather than the trimId.
    try {
      tireDetails = await APIRouter('FIT', 'getVehicleAttributes', {
        year, make, model, trim: vehicleInfo.trimId || trim, assembly: (assemblyCode && assembly && assembly !== constants.NOT_APPLICABLE) ? assembly?.assemblyId : assembly?.assemblyId || null
      }, setLoader);
      // NOTE: Only error triggers the setting of blank tireDetails, temp way of
      // allowing UI to not throw
      if (checkIfObjectHasKey(tireDetails?.data, 'getVehicleAttributes') || tireDetails === undefined) {
        tireDetails = tireTemplate;
      }

    } catch (e) {
      // Error handling modals
      console.error(e);
      tireDetails = tireTemplate;
    }
  } else if (isGeneric) {
    //  NOTE: without a year, make, model the only assembly info we can
    //  potentially access would be for a generic. Generics work based
    //  on category and subcategory selection rather than YMM.
    tireDetails = await APIRouter('FIT', 'getGenericAssemblies', {
      vehicleCategory: overrideVehicleCategory,
      vehicleSubcategory: overrideVehicleSubCategory
    }, setLoader);
    //  TODO: getGenericAssemblies returns an array. How do we want to
    //  filter that result set?
    if (tireDetails) {
      tireDetails = tireDetails[0];
    }
    if (!tireDetails) {
      tireTemplate.vehicleTireData[0] = vehicleInfo.assemblyCollection?.filter((item) => {
        //  TODO: the data for generic is not consistent
        return item?.assemblyItem?.assemblyType === constants.VEHICLE_GENERIC_ASSEMBLY_TYPE
        || item?.assemblyItem?.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE;
      })[0]?.assemblyItem;
      const genericAssembly = tireTemplate.vehicleTireData[0];
      tireTemplate.vehicleTireData[0] = {
        ...genericAssembly,
        ...{
          boltPattern: vehicle.overrideFrontBoltPattern || constants.EMPTY_STRING,
          rearBoltPattern: vehicle.overrideRearBoltPattern || constants.EMPTY_STRING,
          frontTirePressure: vehicle.overrideFrontTirePressure || constants.EMPTY_STRING,
          rearTirePressure: vehicle.overrideRearTirePressure || constants.EMPTY_STRING,
          torque: vehicle.overrideFrontTorque || constants.EMPTY_STRING,
          rearTorque: vehicle.overrideRearTorque || constants.EMPTY_STRING,
          lugNutSize: vehicle.overrideFrontLugNut || constants.EMPTY_STRING,
          rearLugNutSize: vehicle.overrideRearLugNut || constants.EMPTY_STRING,
          factoryTireSize: `${genericAssembly?.frontTireWidth || constants.EMPTY_STRING}/${genericAssembly?.frontTireAspectRatio || constants.EMPTY_STRING} R${genericAssembly?.frontTireDiameter || constants.EMPTY_STRING}`,
          rearFactoryTireSize: vehicle.isStaggered ? `${genericAssembly.rearTireWidth || constants.EMPTY_STRING}/${genericAssembly.rearTireAspectRatio || constants.EMPTY_STRING} R${genericAssembly.frontTireDiameter || constants.EMPTY_STRING}` : constants.EMPTY_STRING,
          wheelSize: vehicle.isStaggered ? {
            front: genericAssembly?.frontRimSize || constants.EMPTY_STRING,
            back: genericAssembly?.rearRimSize || constants.EMPTY_STRING
          } : genericAssembly?.frontRimSize || constants.EMPTY_STRING
        }
      };
    }

    tireDetails = tireTemplate;
  } else {
    //  TODO: what now? no ymmt and/or cat/subcat. Provide a blank block?
    tireDetails = tireTemplate;
  }
  return tireDetails;
};
//
// Adds missing information for vehicle modal to load normally for inspection vehicles
//
export const getFormattedInspectionData = (inspection, vehicle, customer, isIncompleteVehicle) => {
  const inspectionData = { ...inspection };
  // NOTE: Ask business if i can rely on manual customerVehicleId from CVM
  inspectionData.customerVehicleId = vehicle?.vehicleDetails?.vehicleId || constants.EMPTY_STRING;

  inspectionData.vehicle = vtvAircheckVehicle(vehicle, isIncompleteVehicle);
  inspectionData.customer = vtvAircheckCustomer(customer);
  // Need better way of getting mileage removed, post doesnt like empty or non int values
  if (typeof inspectionData?.mileage === 'string') inspectionData.mileage = parseInt(inspectionData?.mileage?.replace(/[^0-9.]/g, ''), 10);
  if (!inspectionData?.mileage) delete inspectionData.mileage;
  // Device vtv inspections have a fallback generic vin. This breaks CVM functionality
  if (inspectionData.vehicle?.vin && inspectionData.vehicle?.vin.includes('DTCVIN')) inspectionData.vehicle.vin = constants.EMPTY_STRING;

  return inspectionData;
};
//
// Adds missing information for vehicle modal to load normally for inspection vehicles
//
export const getIncompleteInspectionVehicle = (vehicle, isEdit, getStateOptions) => {
  const vehicleFormat = !isEdit ? vtvAircheckAddVehicle(vehicle, getStateOptions, true)[0] : deepObjectCopy(vehicle);
  // NOTE: If the format for vehicle modal needs to be updated to in this for inspection
  const vehicleAddUpdateFormat =  {
    ...vehicleFormat,
    vehicleId: isEdit ? vehicle.vehicleId : '',
    id: isEdit ? vehicle.id || '' : '',
    isIncomplete: true,
    vin: vehicle.vin || '',
    licensePlateNumber: vehicle.licensePlate || '',
    licenseState: vehicle.licensePlateState || '',
  };
  return { vehicleDetails: vehicleAddUpdateFormat };
};


//
// Determines if a vehicle is defined as Incomplete from inspection. Only to be used in add vehicle
//
export const isIncompleteInspectionVehicle =  (vehicle) => {
  return isNullEmptyUndefined(vehicle?.year) ||
    isNullEmptyUndefined(vehicle?.make) ||
    isNullEmptyUndefined(vehicle?.model) ||
    (isNullEmptyUndefined(vehicle?.trim) &&
      isNullEmptyUndefined(vehicle?.trimId) &&
      isNullEmptyUndefined(vehicle?.trimDescription)) ||
    isNullEmptyUndefined(vehicle?.frontAssembly) || vehicle?.frontAssembly === 'n/a';
};
//
// Determines if a vehicle is defined as Incomplete. If incomplete, it will need to be edited before sending to GK
//
export const isIncompleteVehicle = async (vehicle) => {
  let isIncomplete = isNullEmptyUndefined(vehicle?.year) ||
    isNullEmptyUndefined(vehicle?.make) ||
    isNullEmptyUndefined(vehicle?.model) ||
    (isNullEmptyUndefined(vehicle?.trim) &&
      isNullEmptyUndefined(vehicle?.trimId) &&
      isNullEmptyUndefined(vehicle?.trimDescription)) ||
    isNullEmptyUndefined(vehicle?.assemblyCollection);

  // TODO: (ss) note the above has no check for "we already tried to normalize
  // and I'm sorry but the details (assemblies etc.) simply aren't available"
  // so we can, in some cases, just keep calling without ever resolving. There
  // should be a "we did everything we could to fix this vehicle" flag too.

  // put in as a safeguard to prevent mis-entered assemblies from non-CVM created vehicles
  if (isNullEmptyUndefined(vehicle?.trim) &&
      isNullEmptyUndefined(vehicle?.trimId) &&
      isNullEmptyUndefined(vehicle?.trimDescription)) {
    vehicle.assemblyCollection = [];
  }

  // TODO: (ss) The rest of this call _should not happen here_. At best it
  // should be moved to a "tryToCompleteVehicle" call that can optionally be
  // called on vehicles which return true from isIncompleteVehicle() calls...
  // but really most vehicles should use the API layer's normalization routines
  // so we don't duplicate that logic in too many places. (Note that some
  // variant of this also happens in the "got a vehicle from GK" code path).
  // fill out trims if possible
  if (vehicle?.trimId && (isNullEmptyUndefined(vehicle.trim) || isNullEmptyUndefined(vehicle.trimDescription))) {
    const trims = await APIRouter('FIT', 'getVehicleTrims', { year: vehicle.year, make: vehicle.make, model: vehicle.model });

    if (trims) {
      const trim = trims?.vehicleTrim?.trimCollection?.find(t => t.trimItem.trimId === vehicle.trimId);

      if (trim) {
        vehicle.trimDescription = trim.trimItem.trimName;
        vehicle.trim = trim.trimItem.trimName;
      }
    }
  }

  if (isIncomplete && vehicle?.assemblyCollection?.length > 0) {
    if (vehicle.year && vehicle.make && vehicle.model && (vehicle.trimId || vehicle.trim || vehicle.trimDescription)) {

      const incomingAssemblyCode = vehicle.assemblyCollection && vehicle.assemblyCollection[0]?.assemblyItem?.assemblyCode;

      // if we have a trim now with YMM and there's an attached assembly, normalize assembly based on assemblyCode if present in assemblyItem
      if (incomingAssemblyCode) {
        const assemblies = await getVehicleAssemblies(
          {
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            trim: vehicle.trimId || vehicle.trimDescription || vehicle.trim
          }
        );

        if (assemblies?.length > 0) {
          assemblies.forEach(a => {
            if (a.assembly.assemblyCode === incomingAssemblyCode) {
              vehicle.assemblyCollection = [];
              a.assembly.isCVM = false;
              vehicle.assemblyCollection.push({
                assemblyItem: {
                  assemblyCode: a.assembly.assemblyCode,
                  assemblyId: a.assembly.assemblyId,
                  assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                  fitmentId: a.assembly.fitmentId,
                  frontRimSize: a.assembly.wheelSize?.toString(),
                  frontTireAspectRatio: a.assembly.aspectRatio,
                  frontTireDiameter: a.assembly.wheelSize,
                  frontTireWidth: a.assembly.crossSection,
                  rearRimSize: a.assembly.wheelSize?.toString(),
                  rearTireAspectRatio: a.assembly.aspectRatio,
                  rearTireDiameter: a.assembly.wheelSize,
                  rearTireWidth: a.assembly.crossSection,
                  sizeQualifier: '0',
                  tireSize: a.assembly.tireSize,
                  wheelSize: a.assembly.wheelSize,
                  isOE: true,
                  isAfterMarket: false
                }
              });
            }
          });
        }
      }
    }
  }

  // check if assemblyCollection has multiple OEs
  const oeAssemblies = vehicle?.assemblyCollection?.filter(ac => ac.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE) || [];
  const restructuredAssemblies = [];
  if (oeAssemblies?.length > 1) {
    vehicle?.assemblyCollection.forEach(item => {
      if (item.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE || item.assemblyItem.assemblyType === 'O.E.') {
        if (item.assemblyItem.currentAssembly) {
          restructuredAssemblies.push(item);
        }
      } else if (item.assemblyType) {
        restructuredAssemblies.push(item);
      }
    });

    vehicle.assemblyCollection = restructuredAssemblies;
  }


  // ensure each assemblyItem has an assemblyType. This is important to determine OE, WTR, ALT in the Edit mode
  if (vehicle?.assemblyCollection?.length > 0) {
    const numberOfMissingAssemblyTypeFields = vehicle.assemblyCollection.filter(item => isNullEmptyUndefined(item.assemblyItem.assemblyType));
    const oeAssembly = vehicle.assemblyCollection.filter(item => item.assemblyItem.assemblyType === 'OE');

    if (numberOfMissingAssemblyTypeFields?.length > 1) {
      vehicle.assemblyCollection = [];
      isIncomplete = true;
    } else if (numberOfMissingAssemblyTypeFields?.length === 1 && oeAssembly?.length === 0) {
      vehicle.assemblyCollection.forEach(item => {
        if (isNullEmptyUndefined(item.assemblyItem.assemblyType)) {
          item.assemblyItem.assemblyType = 'OE';
        }
      });
    }
  }

  return isIncomplete;
};

//
// Schematic design to check where an incomplete vehicle needs to be placed in the edit workflow
// returns a vehicle breadcrumb step
//
export const incompleteVehicleBreadcrumb = (vehicle, breadcrumbs) => {
  let index = 5,
    breadcrumbCopy = deepObjectCopy(breadcrumbs);
  if (!vehicle.year) {
    breadcrumbCopy = breadcrumbs;
    index = 0;
  } else if (!vehicle.make) {
    index = 1;
  } else if (!vehicle.model) {
    index = 2;
  } else if (!vehicle.trim && !vehicle.trimDescription) {
    index = 3;
  } else if (!vehicle.assemblyCollection || isEmptyArray(vehicle.assemblyCollection)) {
    index = 4;
  }

  return [index, breadcrumbCopy];
};
// NOTE temporary way of checking generic vehicle data coming in as two values
export const isGenericVehicle = (vehicle) => {
  return (
    vehicle?.type === constants.VEHICLE_TYPE_GENERIC ||
    vehicle?.overrideDescription === constants.VEHICLE_TYPE_GENERIC ||
    vehicle?.overrideDescription === constants.VEHICLE_TYPE_GENERIC_VEHICLE
  );
};
export const oneTimeVehicleInfo = (vehicleInfo) => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  return {
    ...vehicleInfo,
    startDate: today,
    endDate: today,
    isOneTime: true,
  };
};

export const addCustomerVehicleStructure = (customer, vehicle) => {
  return {
    customerInfo: {
      customerId: customer?.id || constants.EMPTY_STRING,
      customerType: customer?.customerType || constants.EMPTY_STRING,
    },
    vehicleInfo: {
      year: vehicle.overrideYear || 0,
      model: vehicle.model  || vehicle.overrideModel || constants.EMPTY_STRING,
      make: vehicle.make || vehicle.overrideMake || constants.EMPTY_STRING,
      type: constants.VEHICLE_TYPE_GENERIC,
      vin: vehicle.vin || constants.EMPTY_STRING,
      licensePlateNumber: vehicle.licensePlateNumber || constants.EMPTY_STRING,
      licensePlateStateCode: vehicle.licensePlateStateCode || constants.EMPTY_STRING,
      licensePlateCountryCode: vehicle.licensePlateCountryCode || constants.EMPTY_STRING,
      color: vehicle.color || constants.EMPTY_STRING,
      chassisCode: vehicle.chassisCode || constants.EMPTY_STRING,
      fitmentLiftedId: vehicle.fitmentLiftedId || constants.EMPTY_STRING,
      trimId: vehicle.trimId || constants.EMPTY_STRING,
      overrideModel: vehicle.overrideModel || constants.EMPTY_STRING,
      overrideMake: vehicle.overrideMake || constants.EMPTY_STRING,
      imageUrl: vehicle.imageUrl || constants.EMPTY_STRING,
      trimDescription: vehicle.trimDescription || constants.EMPTY_STRING,
      isDualRearWheel: vehicle.isDually || false,
      isDually: vehicle.isDually || false,
      overrideTrim: vehicle.overrideTrim || constants.EMPTY_STRING,
      fitmentVehicleId: vehicle.fitmentVehicleId || constants.EMPTY_STRING,
      isIhs: vehicle.isIhs || false,
      overrideVehicleCategory: vehicle.overrideVehicleCategory || constants.EMPTY_STRING,
      overrideVehicleSubCategory: vehicle.overrideVehicleSubCategory || constants.EMPTY_STRING,
      overrideFrontTirePressure: vehicle.overrideFrontTirePressure ||  constants.EMPTY_STRING,
      overrideRearTirePressure: vehicle.overrideRearTirePressure ||  constants.EMPTY_STRING,
      overrideFrontBoltPattern: vehicle.overrideBoltPattern ||  constants.EMPTY_STRING,
      overrideFrontLugNut: vehicle.overrideLugNut ||  constants.EMPTY_STRING,
      overrideFrontTorque: vehicle.overrideTorque ||  constants.EMPTY_STRING,
      overrideRearBoltPattern: vehicle.overrideRearBoltPattern ||  constants.EMPTY_STRING,
      overrideRearLugNut: vehicle.overrideRearLugNut ||  constants.EMPTY_STRING,
      overrideRearTorque: vehicle.overrideRearTorque ||  constants.EMPTY_STRING,
      isWheelLocked: vehicle.isWheelLocked || false,
      isStaggered: vehicle.isStaggered || false,
      notes: vehicle.notes || constants.EMPTY_STRING,
      classificationCode: vehicle.classificationCodes || constants.EMPTY_STRING,
      isOfflineCreation: vehicle.isOfflineCreation || false,
      overrideDescription: vehicle?.overrideDescription || constants.EMPTY_STRING,
      vehicleClass: vehicle.overrideDescription || constants.EMPTY_STRING,
      overrideYear: vehicle.overrideYear || 0,
      // TO DO: unsure on what to add as start and end date
      endDate: vehicle.endDate ||  '2024-06-24',
      startDate: vehicle.startDate || moment().format('YYYY-MM-DD'),
      isActive: !vehicle?.isDeactivated,
      isOneTime: vehicle?.isOneTime || false,
      relationshipId: vehicle.relationshipId || constants.EMPTY_STRING,
      assemblyCollection: vehicle?.assemblyCollection ? vehicle.assemblyCollection : [{
        assemblyItem: {}
      }],
      alternateIdentifierCollection: vehicle?.alternateIdentifierCollection ?
        vehicle.alternateIdentifierCollection : []
    }
  };
};


export const vtvAircheckBaseData = (service, vehicle) => {
  const initialServiceData = {
    reason: service?.reason || 'service',
    tpmsStatus: service?.tpmsStatus || 'Off',
    replaceAllWheels: service?.replaceAllWheels || false,
    source: service?.source || 'CVM',
    replaceAllTires: service?.replaceAllTires || false,
    isSpareInUse: service?.isSpareInUse || false,
    vehicle:
    {
      year: vehicle?.year,
      assemblyId: vehicle?.assemblyId,
      rearPSI: vehicle?.rearPSI,
      frontAssembly: vehicle?.frontAssembly,
      trimName: vehicle?.trimName || constants.EMPTY_STRING,
      chassisId: vehicle?.chassisId,
      frontPSI: vehicle?.frontPSI,
      isDualRearWheel: vehicle?.isDualRearWheel || false,
      licensePlate: vehicle?.licensePlate || constants.EMPTY_STRING,
      isNonOE: vehicle?.isNonOE || false,
      trimId: vehicle?.trimId,
      rearAssembly: vehicle?.frontAssembly || constants.EMPTY_STRING,
      imageURL: vehicle?.imageUrl || constants.EMPTY_STRING,
      licensePlateState: vehicle?.licensePlateState || constants.EMPTY_STRING,
      vin: vehicle?.vin || constants.EMPTY_STRING,
      model: vehicle?.model || constants.EMPTY_STRING,
      vehicleId: vehicle?.vehicleId || constants.EMPTY_STRING,
      isStaggered: vehicle?.isStaggered || false,
      make: vehicle?.make || constants.EMPTY_STRING
    },
    dotCommunicationOptIn: service?.dotCommunicationOptIn || false,
    wheelLock: service?.wheelLock || false,
    customerDroppingOff: service?.customerDroppingOff ||  false,
    customerVehicleId: vehicle?.vehicleId || service?.customerVehicleId  || constants.EMPTY_STRING,
    auditDateTime: `${moment.utc().format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
    yearKey: moment().format('YYYY'),
    audit: {
      dateTime: moment.utc().format('MMDDYYYYHHmmss'),
      siteId: parseInt(localStorage.getItem('siteId'), 10),
      source: service?.audit?.source || 'CVM',
      deviceId: service?.audit?.deviceId || '',
      employeeNumber: service?.audit?.employeeNumber || '',
      storeCode: localStorage.getItem('storeCode'),
    },
    mileage: formatMileage(service?.mileage) || constants.EMPTY_STRING,
    comments: service?.comments || constants.EMPTY_STRING,
    condition: service?.condition || constants.EMPTY_STRING,
    isCarryOut: service?.isCarryOut || false,
    inspectionId: service?.inspectionId || constants.EMPTY_STRING,
    yearkey: moment().format('YYYY'),
    siteId: parseInt(localStorage.getItem('siteId'), 10),
    location: service?.location || constants.EMPTY_STRING, // change this value
    rotationPattern: service?.rotationPattern || 'None',
  };
  // To determine the type of vehicle and attach right number of tires
  let tires = 5;
  if (isNullEmptyUndefined(service)) {
    if (vehicle?.isDualRearWheel || vehicle?.isDually) {
      tires = 7;
    }
  } else if (service.vehicle?.isDualRearWheel) {
    tires = 7;
  }
  const collection = [];
  for (let i = 0; i < tires; i++) {
    let tire;
    if (service?.tireCollection?.[i]?.tireItem) tire = service.tireCollection[i].tireItem;
    const tireItem = {
      rawReadingCollection: tire?.rawReadingCollection || [],
      tireAge: constants.EMPTY_STRING,
      tireLocation: tire?.tireLocation || constants.EMPTY_STRING,
      loadRange: tire?.loadRange ||   '', // Change this to a value
      loadIndex: tire?.loadIndex ||  '', // Change this to a value
      servicesPerformed: tire?.servicesPerformed || '',
      treadEntryMode: 'manual',
      dotNumber: tire?.dotNumber || constants.EMPTY_STRING, // change this to be a dot value // change this to be a dot value
      tireStatus: tire?.tireStatus || '',
      recallFlag: tire?.recallFlag || false,
    };
    if (tire?.recallDetails) {
      tireItem.recallDetails = {
        additionalInfo: tire?.recallDetails.additionalInfo || '',
        recallName: tire?.recallDetails.recallName || '',
        validFrom: tire?.recallDetails.validFrom || '',
        nhtsaId: tire?.recallDetails.nhtsaId || '',
        validTo: tire?.recallDetails.validTo || '',
      };
    }
    const treadCollection = [];
    for (let j = 0; j < 3; j++) {
      const tread = tire?.treadDepthCollection?.[j]?.treadDepthItem;
      // NOTE: WOM was sending incorrect data this is a catch all for that
      if (tread && !isNullEmptyUndefined(tread?.location)) tread.location = tread.location.charAt(0).toUpperCase() + tread.location.slice(1);
      treadCollection.push({
        treadDepthItem: {
          confidence: tread?.confidence || '100',
          reading: tread?.reading || constants.EMPTY_STRING,  // '4',
          location: tread?.location === 'Middle' ? 'Center' : tread?.location || constants.TREADS[j]
        }
      });
    }
    tireItem.treadDepthCollection = treadCollection;
    collection.push({ tireItem });
  }
  const missingTires = tires === 7 ?
    constants.DUALLY_VTV.filter(tireLoc => collection.findIndex(tire => tire.tireItem.tireLocation === tireLoc) < 0) :
    constants.STANDARD_VTV.filter(tireLoc => collection.findIndex(tire => tire.tireItem.tireLocation === tireLoc) < 0);
  collection.forEach((tire, i) => {
    if (!tire.tireItem.tireLocation) tire.tireItem.tireLocation = missingTires.shift();
  });
  initialServiceData.tireCollection = collection;
  return initialServiceData;
};

export const vtvAircheckCustomer = (customer) => {
  // The reason this is standalone is because the vtv/aircheck fields need to check for
  // changes in data not the customer, that should always be editable and posted without CTA
  // Append this in vtv fields post example vtvFields.customer =
  let phone = customer.mobileNumber || customer.phoneNumber || customer.workNumber || constants.EMPTY_STRING;
  phone = phone.replace(/[^0-9]/g, constants.EMPTY_STRING);
  return {
    firstName: customer?.firstName || constants.EMPTY_STRING,
    lastName: customer?.lastName || constants.EMPTY_STRING,
    // lastUpdated: '',
    phone,
    // This needs to be the customer id from inspection or hard coded seems we cant pass empty inspection id
    customerId: customer?.id || customer?.customerId || 'V000000018',
    // customerMiddleInitial: customer?.middleName || constants.EMPTY_STRING,
    email: customer.email || constants.EMPTY_STRING,
    customerType: customer.customerType || constants.STANDARD_CUSTOMER_TYPE,
    companyName: customer.companyName || constants.EMPTY_STRING,
    companyName2: customer.companyName2 || constants.EMPTY_STRING,
  };
};
export const genericYMM = (vehicle) => vehicle?.overrideYear !== 0 && vehicle?.overrideYear && vehicle?.overrideMake && vehicle?.overrideModel && vehicle?.type === constants.VEHICLE_TYPE_GENERIC;

export const vtvAircheckVehicle = (vehicle) => {
  // Going to need to divide this with references to generic vehicle values
  const assembly = vehicle.vehicleDetails?.assemblyCollection?.filter(ac => ac.assemblyItem.currentAssembly)?.[0]?.assemblyItem || vehicle.vehicleDetails.assemblyCollection[0]?.assemblyItem;
  const vehDetails = vehicle.vehicleDetails;
  const tire = vehicle?.tireDetails?.[0] && vehicle.tireDetails[0];
  const isGeneric = isGenericVehicle(vehicle?.vehicleDetails);
  let vehicleData = {
    // (ss) - fix CAVA-628
    // assemblyId: assembly.assemblyId || constants.EMPTY_STRING,
    assemblyId: assembly?.assemblyCode || constants.EMPTY_STRING,

    // (ss) - fix CAVA-628
    // chassisId: assembly.assemblyCode || constants.EMPTY_STRING,
    chassisId: vehDetails.chassisCode || constants.EMPTY_STRING,

    frontAssembly: `${assembly?.frontTireWidth}/${assembly?.frontTireAspectRatio} ${assembly?.frontTireDiameter}` || constants.EMPTY_STRING,
    frontPSI: tire?.frontTirePressure.toString() || constants.EMPTY_STRING,
    imageURL: vehDetails.imageUrl || constants.EMPTY_STRING,
    isDualRearWheel: vehDetails.isDualRearWheel || false,
    isNonOE: !assembly?.assemblyType === 'OE' || false,
    isStaggered: vehDetails.isStaggered || false,
    licensePlate: vehDetails.licensePlateNumber?.toUpperCase() || constants.EMPTY_STRING,
    licensePlateState: vehDetails.licenseState || constants.EMPTY_STRING,
    // TO DO: this is temporary way to account for generic vehicles that are missing YMM
    make: vehDetails?.make || constants.EMPTY_STRING,
    model: vehDetails?.model || constants.EMPTY_STRING,
    rearAssembly: vehDetails?.isStaggered ? `${assembly?.rearTireWidth}/${assembly?.rearTireAspectRatio} ${assembly?.rearTireDiameter}` : constants.EMPTY_STRING,
    rearPSI: tire?.frontTirePressure.toString() || constants.EMPTY_STRING,
    trimId: vehDetails.trimId,
    trimName: vehDetails?.trim || constants.EMPTY_STRING,

    // (ss) - fix CAVA-628
    // vehicleId: vehDetails?.vehicleId || constants.EMPTY_STRING,
    vehicleId: vehDetails?.fitmentVehicleId || constants.EMPTY_STRING,

    vin: vehDetails?.vin?.toUpperCase() || constants.EMPTY_STRING,
    year: vehDetails?.year?.toString() || constants.EMPTY_STRING,
  };
  if (isGeneric) {
    vehicleData = {
      ...vehicleData,

      // (ss) - fix CAVA-667... make sure Generics retain vehicleId (not fitmentVehicleId)
      vehicleId: vehDetails?.vehicleId || constants.EMPTY_STRING,

      overrideVehicleCategory: vehDetails.overrideVehicleCategory || null,
      overrideVehicleSubCategory: vehDetails.overrideVehicleSubCategory || null,
      overrideYear: vehDetails.overrideYear || null,
      overrideMake: vehDetails.overrideMake || null,
      overrideModel: vehDetails.overrideModel || null,
      overrideTrim: vehDetails.overrideTrim || null
    };
    if (!vehicleData.overrideYear) delete vehicleData.overrideYear;
  }
  return vehicleData;
};


export const validTreadFields = (collection) => {
  if (!collection) return false;
  return collection.map(tire => {
    if (tire.tireItem.treadDepthCollection.every(tread => tread.treadDepthItem?.reading)) return true;
    if (tire.tireItem.treadDepthCollection.every(tread => !tread.treadDepthItem?.reading)) return true;
    return false;
  }).every(field => field);
};
export const getUpdatedVehicleAssembly = (standardVehicleFields, value) => {
  const assemblyCollection = [];
  let newFields,
    existingOEAssemblyId;
  Object.assign(assemblyCollection, standardVehicleFields.assemblyCollection);
  // remove existing OE in the list if selecting a new one
  assemblyCollection.forEach((ac, index) => {
    if (ac.assemblyItem?.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE) {
      existingOEAssemblyId = ac.assemblyItem.assemblyId;
      assemblyCollection.splice(index, 1);
    }
  });
  if (Array.isArray(value.assembly)) {
    // update the assemblyCollection
    assemblyCollection.push({
      assemblyItem: {
        assemblyCode: value.assembly[0].assemblyCode,
        assemblyId: existingOEAssemblyId || value.assembly[0].assemblyId,
        assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
        fitmentId: value.assembly[0].fitmentId,
        frontRimSize: value.assembly[0].wheelSize?.toString(),
        frontTireAspectRatio: value.assembly[0].aspectRatio,
        frontTireDiameter: value.assembly[0].wheelSize,
        frontTireWidth: value.assembly[0].crossSection,
        rearRimSize: value.assembly[1].wheelSize?.toString(),
        rearTireAspectRatio: value.assembly[1].aspectRatio,
        rearTireDiameter: value.assembly[1].wheelSize,
        rearTireWidth: value.assembly[1].crossSection,
        sizeQualifier: '0',
        tireSize: value.assembly[0].tireSize,
        wheelSize: value.assembly[0].wheelSize,
        isOE: true,
        isAfterMarket: false
      }
    });
    newFields = {
      ...standardVehicleFields,
      assemblyCollection,
      selectedAssembly: [
        {
          assemblyCode: value.assembly[0].assemblyCode,
          assemblyId: existingOEAssemblyId || value.assembly[0].assemblyId,
          assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
          fitmentId: value.assembly[0].fitmentId,
          frontRimSize: value.assembly[0].wheelSize?.toString(),
          frontTireAspectRatio: value.assembly[0].aspectRatio,
          frontTireDiameter: value.assembly[0].wheelSize,
          frontTireWidth: value.assembly[0].crossSection,
          rearRimSize: value.assembly[1].wheelSize?.toString(),
          rearTireAspectRatio: value.assembly[1].aspectRatio,
          rearTireDiameter: value.assembly[1].wheelSize,
          rearTireWidth: value.assembly[1].crossSection,
          sizeQualifier: '0',
          tireSize: value.assembly[0].tireSize,
          wheelSize: value.assembly[0].wheelSize,
          isOE: true,
          isAfterMarket: false,
        },
        {
          assemblyCode: value.assembly[1].assemblyCode,
          assemblyId: existingOEAssemblyId || value.assembly[1].assemblyId,
          assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
          fitmentId: value.assembly[0].fitmentId,
          frontRimSize: value.assembly[0].wheelSize?.toString(),
          frontTireAspectRatio: value.assembly[0].aspectRatio,
          frontTireDiameter: value.assembly[0].wheelSize,
          frontTireWidth: value.assembly[0].crossSection,
          rearRimSize: value.assembly[1].wheelSize?.toString(),
          rearTireAspectRatio: value.assembly[1].aspectRatio,
          rearTireDiameter: value.assembly[1].wheelSize,
          rearTireWidth: value.assembly[1].crossSection,
          sizeQualifier: '0',
          tireSize: value.assembly[1].tireSize,
          wheelSize: value.assembly[1].wheelSize,
          isOE: true,
          isAfterMarket: false
        }
      ],
      chassisId: value.assembly[0].chassisId
    };
    return newFields;
  }

  assemblyCollection.push({
    assemblyItem: {
      assemblyCode: value.assembly.assemblyCode,
      assemblyId: existingOEAssemblyId || value.assembly.assemblyId,
      assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
      fitmentId: value.assembly.fitmentId,
      frontRimSize: value.assembly.wheelSize?.toString(),
      frontTireAspectRatio: value.assembly.aspectRatio,
      frontTireDiameter: value.assembly.wheelSize,
      frontTireWidth: value.assembly.crossSection,
      rearRimSize: value.assembly.wheelSize?.toString(),
      rearTireAspectRatio: value.assembly.aspectRatio,
      rearTireDiameter: value.assembly.wheelSize,
      rearTireWidth: value.assembly.crossSection,
      sizeQualifier: '0',
      tireSize: value.assembly.tireSize,
      wheelSize: value.assembly.wheelSize,
      isOE: true,
      isAfterMarket: false
    }
  });
  newFields = {
    ...standardVehicleFields,
    assemblyCollection,
    selectedAssembly: {
      assemblyCode: value.assembly.assemblyCode,
      assemblyId: existingOEAssemblyId || value.assembly.assemblyId,
      assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
      fitmentId: value.assembly.fitmentId,
      frontRimSize: value.assembly.wheelSize?.toString(),
      frontTireAspectRatio: value.assembly.aspectRatio,
      frontTireDiameter: value.assembly.wheelSize,
      frontTireWidth: value.assembly.crossSection,
      rearRimSize: value.assembly.wheelSize?.toString(),
      rearTireAspectRatio: value.assembly.aspectRatio,
      rearTireDiameter: value.assembly.wheelSize,
      rearTireWidth: value.assembly.crossSection,
      sizeQualifier: '0',
      tireSize: value.assembly.tireSize,
      wheelSize: value.assembly.wheelSize,
      isOE: true,
      isAfterMarket: false,
    },
    chassisId: value.assembly.chassisId
  };
  return newFields;
};


export const getVehicleAssemblies = async (standardVehicleFields, setLoader = () => {}) => {
  let assemblies = ({});
  try {
    // Changed the references to values slightly
    const oeResponse = await APIRouter('FIT', 'getVehicleAssemblies', ({
      year: standardVehicleFields.year,
      make: standardVehicleFields.make,
      model: standardVehicleFields.model,
      trim: standardVehicleFields.trimId || standardVehicleFields.trim || standardVehicleFields.trimDescription,
    }), setLoader, true);
    const oe = [];
    let loadRange = constants.EMPTY_STRING;
    oeResponse.forEach(oeR => {
      // each oe variable represents an assembly
      // if staggered, then there will be 2 items in the OE assemblyDescriptionCollection array
      if (oeR.isStaggered === 'true') {
        const staggeredAssembly = [{}, {}];
        let hasAxleType = false;
        oeR.assemblyDescriptionCollection.forEach((adc, index) => {
          let indexToUse = index;
          loadRange = adc.assemblyDescriptionItem.loadRange;
          hasAxleType = !isNullEmptyUndefined(oeR.assemblyDescriptionCollection[0]?.assemblyDescriptionItem?.axleType) && !isNullEmptyUndefined(oeR.assemblyDescriptionCollection[1]?.assemblyDescriptionItem?.axleType);

          if (hasAxleType) {
            indexToUse = adc.assemblyDescriptionItem.axleType === 'F' ? 0 : 1;
          }

          staggeredAssembly[indexToUse] = {
            assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
            aspectRatio: adc.assemblyDescriptionItem.aspectRatio,
            crossSection: adc.assemblyDescriptionItem.crossSection,
            tireSize: `${adc.assemblyDescriptionItem.crossSection}/${adc.assemblyDescriptionItem.aspectRatio} ${constants.ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL}${adc.assemblyDescriptionItem.diameter} ${loadRange}`,
            wheelSize: adc.assemblyDescriptionItem.diameter,
            // temporarily do it this way since the backend is feeding Id as Code
            assemblyCode: oeR.assemblyCode || oeR.assemblyId,
            chassisId: oeR.chassisId,
            fitmentId: oeR.fitmentId
          };
        });
        oe.push({
          isStaggered: true,
          assembly: staggeredAssembly
        });
      } else {
        oeR.assemblyDescriptionCollection.forEach(adc => {
          loadRange = adc.assemblyDescriptionItem.loadRange;
          oe.push({
            isStaggered: false,
            assembly: {
              assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
              aspectRatio: adc.assemblyDescriptionItem.aspectRatio,
              crossSection: adc.assemblyDescriptionItem.crossSection,
              tireSize: `${adc.assemblyDescriptionItem.crossSection}/${adc.assemblyDescriptionItem.aspectRatio} ${constants.ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL}${adc.assemblyDescriptionItem.diameter} ${loadRange}`,
              wheelSize: adc.assemblyDescriptionItem.diameter,
              // temporarily do it this way since the backend is feeding Id as Code
              assemblyCode: oeR.assemblyCode || oeR.assemblyId,
              chassisId: oeR.chassisId,
              fitmentId: oeR.fitmentId
            }
          });
        });
      }
    });
    // sets the assemblies for the tire size breadcrumb
    assemblies = oe;
    return  assemblies;
  } catch (e) {
    console.error('Error reading assembly data');
    // setVehicleErrors({ error: e.errors ? e.errors[0].message : 'Error reading assembly data', currentBreadCrumb });
  }
  return assemblies;
};

