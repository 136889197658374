import moment from 'moment';
import StorageShim from 'node-storage-shim';
import API from 'dt-cvm-api';


import * as constants from '../constants/Constants';
import { isNullEmptyUndefined, isNullOrUndefined, upperCaseFirstLetter } from './validationHelper';

const sessionStorage = globalThis.sessionStorage || new StorageShim();

// ---
// CV Cache
// ---

/**
 * Clears the cached Customer and Vehicle data from the last Apply C/V button.
 * The cache is only intended for immediate use upon return from GK to CVM.
 */
export const clearCVDataCache = () => {
  console.log('Clearing transaction CV cache.');

  sessionStorage.removeItem('TransactionCVCache');
};

/**
 * Returns any cached Customer or Vehicle data from the last Apply C/V button
 * activation. The data used to create the payload for GK is cached during Apply
 * so it can be reused if the application returns to CVM with the same C/V Ids.
 * @param {string} customerId - The customer ID to check against the cached data.
 * @param {string} vehicleId - The vehicle ID to check against the cached data.
 * @returns {object} The customer, vehicle, or C/V data object from the cache.
 */
export const getCachedCVData = (customerId, vehicleId) => {
  let cachedItem;

  const cachedStr = sessionStorage.getItem('TransactionCVCache');
  if (API.utils.notEmpty(cachedStr)) {
    try {
      cachedItem = JSON.parse(cachedStr);

      const cachedCustomerId = cachedItem?.customer?.id || cachedItem?.customer?.customerId;
      const cachedVehicleId = cachedItem?.vehicle?.vehicleDetails?.vehicleId;

      // If no keys were requested we want to return whatever we have and leave
      // checking to the caller.
      if (API.utils.isEmpty(customerId) && API.utils.isEmpty(vehicleId)) {
        console.log(`Returning customer_id=${cachedCustomerId}&vehicle_id=${cachedVehicleId} transaction CV cache item.`);
        return cachedItem;
      }

      if (customerId === cachedCustomerId && vehicleId === cachedVehicleId) {
        console.log(`Found customer_id=${customerId}&vehicle_id=${vehicleId} in transaction CV cache.`);
        return cachedItem;
      }

      if (customerId === cachedCustomerId && API.utils.isEmpty(vehicleId)) {
        console.log(`Found customer_id=${customerId} in transaction CV cache.`);
        return { customer: { ...cachedItem.customer } };
      }

      if (vehicleId === cachedVehicleId && API.utils.isEmpty(customerId)) {
        console.log(`Found vehicle_id=${vehicleId} in transaction CV cache.`);
        return { vehicle: { ...cachedItem.vehicle } };
      }

      cachedItem = null;

    } catch (e) {
      console.error('Error parsing transaction CV cache data: ', e);
      clearCVDataCache();
    }
  }

  return cachedItem;
};

/**
 * Stores a Customer/Vehicle data object (from the Apply Customer/Vehicle call)
 * for reuse in the event the application returns to CVM with the same C/V Ids.
 * @param {object} data - The Customer/Vehicle data object to store in the cache.
 */
export const setCachedCVData = (data) => {

  const customerId = data?.customer?.id || data?.customer?.customerId;
  const vehicleId = data?.vehicle?.vehicleDetails?.vehicleId;

  console.log(`Caching customer_id=${customerId}&vehicle_id=${vehicleId} in transaction CV cache.`);

  sessionStorage.setItem('TransactionCVCache', JSON.stringify(data));
};

// ---

export const defaultBaseData = (name, type, value, label, required) => {
  const newObj = {
    name,
    type,
    required: required || false,
    disabled: false,
    value: constants.EMPTY_STRING,
    label,
    isAutoPopulated: false,
    showPlaceholder: false,
    setValue: (newValue) => {
      newObj.value = newValue;
    },
  };
  newObj.setValue(value);
  return newObj;
};

export const getNormalizeVehicleCountry = (country) =>  {
  switch (country) {
    case 'US':
    case 'USA':
      return countryOptions[0];
    case 'CA':
    case 'CAN':
      return countryOptions[2];
    case 'MX':
    case 'MEX':
      return countryOptions[1];
    default:
      return countryOptions[0];
  }
};

export const countryOptions = [
  { value: 'USA', label: 'United States' },
  { value: 'MX', label: 'Mexico' },
  { value: 'CA', label: 'Canada' },
];
export const countryCodeOptions = [
  { value: 'US', label: 'United States' },
  { value: 'MX', label: 'Mexico' },
  { value: 'CA', label: 'Canada' },
];
export const contactTypeOptions = [
  { value: 'Call', label: 'Call' },
  { value: 'Text', label: 'Text' },
  { value: 'Email', label: 'Email' },
];
export const suffixTypeOptions = [
  { value: 'Jr.', label: 'Jr.' },
  { value: 'Sr.', label: 'Sr.' },
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' },
  { value: 'IV', label: 'IV' },
  { value: 'V', label: 'V' },
];
export const initialCustomerFormData = ({
  firstName: constants.EMPTY_STRING,
  lastName: constants.EMPTY_STRING,
  address1: constants.EMPTY_STRING,
  address2: constants.EMPTY_STRING,
  state: constants.EMPTY_STRING,
  zip: constants.EMPTY_STRING,
  city: constants.EMPTY_STRING,
  country: countryOptions[0],
  isAddressValidated: false,
  phoneNumber: constants.EMPTY_STRING,
  phoneType: constants.EMPTY_STRING,
  phoneCountryCode: constants.UNITED_STATES,
  phoneExt: constants.EMPTY_STRING,
  companyName: constants.EMPTY_STRING,
  phoneCollection: [],
  email: constants.EMPTY_STRING,
  fleetNumber: constants.EMPTY_STRING,
  employeeID: constants.EMPTY_STRING,
  SRPMICNumber: constants.EMPTY_STRING,
});
export const initialInfoEditForm = (data) => {
  const empId = data.alternateIdentifierCollection?.find(ai => ai.alternateIdentifierItem?.name === 'EMPLOYEE ID');
  const initData = {
    customerType: data?.customerType,
    id: data?.id,
    firstName: data?.firstName || constants.EMPTY_STRING,
    middleName: data?.middleName || constants.EMPTY_STRING,
    lastName: data?.lastName || constants.EMPTY_STRING,
    suffix: (typeof (data?.suffix) === 'object' && data?.suffix) || (typeof (data?.suffix) === 'string' && { value: data?.suffix, label: data?.suffix }) || constants.EMPTY_STRING,
    companyName: data?.companyName ? upperCaseFirstLetter(data?.companyName) : constants.EMPTY_STRING,
    AAA: data?.AAA || constants.EMPTY_STRING,
    isMilitary: data?.isMilitary || false,
    DTCC: data?.isSynchronyCardholder?.toString() || constants.EMPTY_STRING,
    employeeID: data?.employeeID || empId?.alternateIdentifierItem?.value || constants.EMPTY_STRING,
    SRPMICNumber: data?.SRPMICNumber ||
      sessionStorage.getItem(`${data?.id}.SRPMICNumber`) ||
      constants.EMPTY_STRING,
    isMyAccount: data?.isMyAccount || false
  };

  return initData;
};
export const phoneTypeOptions = [
  { value: 'mobile', label: 'Mobile' },
  { value: 'work', label: 'Work' },
  { value: 'home', label: 'Home' },
];
export const filterCriteriaTypeOptions = [
  { value: 'licensePlateNumber', label: 'Plate #' },
  { value: 'year', label: 'Year' },
  { value: 'make', label: 'Make' },
  { value: 'model', label: 'Model' },
  { value: 'color', label: 'Color' },
];
export const customerInspectionQuery = data => {
  // NOTE: Only querying by phone
  return {
    phoneNumber: data?.phone ?  data?.phone.replace(/[^0-9]/g, constants.EMPTY_STRING) : constants.EMPTY_STRING,
  };
};
export const removeLeadingZeros = (number) => {
  const regex = /^[0]*([1-9][0-9]{0,4})$/;
  const match = number?.match(regex);
  if (match) {
    return match[1] || null;
  }
};
export const formatCustomerRequest = (data, formType, initialCustomer) => {
  const customer = { ...initialCustomer };
  const isEdit = formType === constants.EDIT_FORM &&
    !isNullEmptyUndefined(data.id) && !isNullEmptyUndefined(initialCustomer);

  if (data.phoneCountryCode === constants.UNITED_STATES) {
    data.phoneCountryCode = constants.EMPTY_STRING;
  }
  let emailItem;
  if (data?.email) {
    emailItem = {
      isDefault: true,
      type: constants.TYPE_EMAIL,
      email: data?.email,
      statusCode: '',
      usageType: constants.USAGE_TYPE
    };
  }
  if (!isEdit) {
    data.phoneType?.value === 'home'
      ? (data.phoneType = constants.HOME_PHONE)
      : data.phoneType?.value === 'work'
        ? (data.phoneType = constants.WORK_PHONE)
        : (data.phoneType = constants.MOBILE_PHONE);
  }
  const phoneItem = {
    phoneType: data?.phoneType,
    internationalCode: data?.phoneCountryCode || constants.EMPTY_STRING,
    phoneNumber: data?.phoneNumber,
    isDefault: true,
  };
  const addressItem = {
    isDefault: true,
    type: 'PRIMARY',
    addressLine1: data?.address1,
    addressLine2: data?.address2,
    city: data?.city,
    postalCode: data?.zip?.includes('-') ? data?.zip?.split('-')[0] : data.zip,
    region: data?.state?.includes('-') ? data.state?.split('-')[1] : data.state,
    // TO DO : country truncation from the api needs to be borrowed to pass what is expected
    country: (data?.country === countryOptions[0].value || data?.country === countryOptions[0].label)
      ? countryCodeOptions[0].value : data.country,
    isAddressValidated: data?.isAddressValidated || false
  };
  const customerInfo = {
    customerType: data.customerType || constants.STANDARD_CUSTOMER_TYPE,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data?.middleName,
    companyName: data.companyName || constants.EMPTY_STRING,
    emailCollection: emailItem ? [{ emailItem }] : [],
    isMilitary: data.isMilitary || false,
    SRPMICNumber: data.SRPMICNumber || constants.EMPTY_STRING,
    phoneCollection: [{ phoneItem }],
    addressCollection: [{ addressItem }],
  };
  if (isEdit) {
    // Update structure differs from the transform that add has and needs a collection
    ['address1', 'address2', 'state', 'country', 'zip', 'city'].forEach(key => delete customerInfo[key]);
    // ADDRESS - structure reconstruct
    if (!customer.addressCollection) customer.addressCollection = [];
    customerInfo.addressCollection = [];
    customerInfo.phoneCollection = [];
    const addresses = [data.address1, data.alternate?.address1].map((address, i) => {
      if (address  !== undefined && address !== null) {
        const primary = i === 0;
        return {
          isDefault: primary,
          addressId: primary ? data.addressId : data.alternate.addressId,
          type: constants.EMPTY_STRING,
          addressLine1: primary ? data.address1 : data.alternate?.address1,
          addressLine2: primary ? data.address2 : data.alternate?.address2,
          city: primary ? data.city : data.alternate.city,
          postalCode: primary ? data.zip.includes('-') ? data.zip.split('-')[0] : data.zip :
            data.alternate.zip.includes('-') ? data.alternate.zip.split('-')[0] : data.alternate.zip,
          postalCodeExtension: primary ? data.zip.includes('-') ? data.zip.split('-')[1] : constants.EMPTY_STRING :
            data.alternate.zip.includes('-') ? data.alternate.zip.split('-')[1] : constants.EMPTY_STRING,
          region: primary ? data.state : data.alternate.state,
          country: primary ? countryCodeOptions.filter(country => data?.country === country.label)[0]?.value :
            (data?.alternate?.country !== constants.EMPTY_STRING ? countryCodeOptions.filter(country => data.alternate?.country === country.label)[0]?.value : data.alternate.country),
          isAddressValidated: primary ? data.isAddressValidated : data.alternate.isAddressValidated,
        };
      }
    });
    addresses?.forEach((address, i) => {
      if (address?.addressId) {
        customer.addressCollection.forEach((addressObj, i) => {
          const initialAddressObj = addressObj?.addressItem;
          if (initialAddressObj?.addressId === address.addressId) {
            customer.addressCollection[i].addressItem = { ...initialAddressObj, ...address };
          }
        });
      } else if (address?.addressLine1) {
        delete address.addressId;
        customer.addressCollection.push({ addressItem: address });
      }
    });
    customerInfo.addressCollection = customer.addressCollection;
    if (customerInfo.addressCollection.length > 2)customerInfo.addressCollection = customerInfo.addressCollection.slice(0, 2);
    // PHONE - sturcture reconstruct
    // TO DO: once internationalCode & phoneExt live will hook up those to workPhone
    const phones = [data.phoneNumber, data.mobileNumber, data.workNumber,
      data.alternate?.phoneNumber, data.alternate?.mobileNumber, data.alternate?.workNumber,
    ].map((phone, i) => {
      phone = phone.replace(/\D/g, '');
      if (i === 0 || i === 3) {
        return {
          phoneNumber: phone || '',
          phoneId: i === 0 ? data.phoneId : data.alternate.phoneId,
          phoneType: 'HOME PHONE',
          isDefault: i === 0,
          internationalCode:
              i === 0
                ? data.phoneCountryCode === 'United States'
                  ? constants.EMPTY_STRING
                  : data.phoneCountryCode
                : data.alternate.phoneCountryCode === 'United States'
                  ? constants.EMPTY_STRING
                  : data.alternate.phoneCountryCode,
        };
      }
      if (i === 1 || i === 4) {
        return {
          phoneNumber: phone || '', phoneId: i === 1 ? data.mobileId : data.alternate.mobileId, phoneType: 'MOBILE PHONE', isDefault: i === 1, internationalCode: i === 1 ? data.mobileCountryCode === 'United States' ? constants.EMPTY_STRING : data.mobileCountryCode : data.alternate.mobileCountryCode === 'United States' ? constants.EMPTY_STRING : data.alternate.mobileCountryCode,
        };
      }
      if (i === 2 || i === 5) {
        return {
          phoneNumber: phone || '', phoneId: i === 2 ? data.workId : data.alternate.workId, phoneType: 'WORK PHONE', isDefault: i === 2, internationalCode: i === 2 ? data.workCountryCode === 'United States' ? constants.EMPTY_STRING : data.workCountryCode : data.alternate.workCountryCode === 'United States' ? constants.EMPTY_STRING : data.alternate.workCountryCode, phoneExtension: i === 2 ? data.workExt : data.alternate.workExt,
        };
      }

    });
    if (!customer.phoneCollection?.length) customer.phoneCollection = [];
    phones?.forEach(phone => {
      if (phone?.phoneId) {
        customer.phoneCollection.forEach((phoneObj, i) => {
          const initialPhoneObj = phoneObj?.phoneItem;
          if (initialPhoneObj?.phoneId === phone?.phoneId) {
            customer.phoneCollection[i].phoneItem = { ...initialPhoneObj, ...phone };
          }
        });
      } else if (phone?.phoneNumber) {
        delete phone.phoneId;
        customer.phoneCollection?.push({ phoneItem: phone });
      }
    });
    customerInfo.phoneCollection = customer.phoneCollection;
    // EMAIL - structure reconstruct
    if (!customer.emailCollection) customer.emailCollection = [];
    const emails = [data.email, data.alternate.email].map((email, i) => {
      const primary = i === 0;
      return {
        email: email || '',
        isDefault: primary,
        type: constants.TYPE_EMAIL,
        usageType: constants.USAGE_TYPE,
        emailId: primary ? data.emailId || '' : data.alternate.emailId || ''
      };
    });


    emails.forEach((email) => {
      if (email?.emailId) {
        customer.emailCollection.forEach((emailObj, i) => {
          const initialEmailObj = emailObj.emailItem;
          if (initialEmailObj?.emailId === email.emailId) {
            customer.emailCollection[i].emailItem = { ...initialEmailObj, ...email };
          }
        });
      } else if (email?.email) {
        customer.emailCollection.push({ emailItem: email });
      }
    });
    const AAAIndex = customer?.alternateIdentifierCollection?.findIndex(obj => obj.alternateIdentifierItem.name === 'AAA');
    customerInfo.alternateIdentifierCollection = [];
    if (data.AAA != null && data.AAA !== '') {
      if (customer?.alternateIdentifierCollection && AAAIndex >= 0) {
        // Replace the existing AAA value with the new one
        customer.alternateIdentifierCollection[AAAIndex].alternateIdentifierItem = { ...customer.alternateIdentifierCollection[AAAIndex].alternateIdentifierItem, value: data.AAA };
      } else {
        // Add a new AAA value to the collection
        const alternateIdentifierItem = { value: data.AAA, name: 'AAA' };
        customer.alternateIdentifierCollection = customer.alternateIdentifierCollection || [];
        customer.alternateIdentifierCollection.push({ alternateIdentifierItem });
      }
    } else if (AAAIndex >= 0) {  // If AAA exists in collection but new value is null/undefined/empty
      customer?.alternateIdentifierCollection.forEach(obj => {
        if (obj.alternateIdentifierItem.name === 'AAA') {
          obj.alternateIdentifierItem.value = constants.EMPTY_STRING;
        }
      });
    }

    customerInfo.alternateIdentifierCollection = customer.alternateIdentifierCollection;

    if (!customerInfo.alternateIdentifierCollection) delete customerInfo.alternateIdentifierCollection;

    customerInfo.emailCollection = customer.emailCollection;
    if (data.middleName) customerInfo.middleName = data.middleName;
    customerInfo.suffix = data.suffix?.toUpperCase();
    // Appends customerInfo with id if it's present for update
    // Add this once phoneCollections are live, added to be able to test other collections
    customerInfo.id = data.id;
  }
  return customerInfo;
};

export const initialVehicleFormData = ({
  vin: constants.EMPTY_STRING,
  licensePlate: constants.EMPTY_STRING,
  licenseState: constants.EMPTY_STRING,
  licenseCountry: constants.EMPTY_STRING,
});
export const vehicleColorOptions = [
  { value: 'black', label: 'Black' },
  { value: 'silver', label: 'Silver' },
  { value: 'gray', label: 'Gray' },
  { value: 'white', label: 'White' },
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'brown', label: 'Brown' },
  { value: 'green', label: 'Green' },
  { value: 'beige', label: 'Beige' },
];
export const getColorOption = (value) => vehicleColorOptions.filter(
  (option) => option.label === value,
);
export const getWidth = (value) => {
  const width = value?.substr(0, value.indexOf('/'));
  if (!width) return '';
  return width;
};
export const getRatio = (value) => {
  let ratio = value?.substr(value.indexOf('/') + 1, value.indexOf(' ') - 2);
  ratio = parseInt(ratio, 10);
  return ratio;
};
export const initialStandardVehicleFormData = (vehicleDetails, tireDetails) => {
  let vehicleObj,
    selectedAssembly = vehicleDetails?.assemblyCollection?.find(ac => ac.assemblyItem.currentAssembly === true);
  if (!selectedAssembly) {
    selectedAssembly = vehicleDetails?.assemblyCollection ? vehicleDetails?.assemblyCollection[0] : null;
  }
  // run this if editing a vehicle
  if (vehicleDetails && !vehicleDetails.isGeneric) {
    // need to reset null values to empty strings (back-end is sending nulls even though I sent them empty strings)
    vehicleDetails.assemblyCollection?.forEach((assembly) => {
      if (isNullOrUndefined(assembly.assemblyItem.frontRimSize)) {
        assembly.assemblyItem.frontRimSize = constants.EMPTY_STRING;
      }
      if (isNullOrUndefined(assembly.assemblyItem.rearRimSize)) {
        assembly.assemblyItem.rearRimSize = constants.EMPTY_STRING;
      }
      if (isNullOrUndefined(assembly.assemblyItem.sizeQualifier)) {
        assembly.assemblyItem.sizeQualifier = '0';
      }
    });


    vehicleObj = {
      id: vehicleDetails.id,
      customerId: vehicleDetails.customerId,
      vin: vehicleDetails.vin || constants.EMPTY_STRING,
      licensePlate: vehicleDetails.licensePlateNumber || constants.EMPTY_STRING,
      licenseState: vehicleDetails.licenseState ? { label: vehicleDetails?.licenseState, value: vehicleDetails?.licenseState } : constants.EMPTY_STRING,
      licenseCountry: vehicleDetails.licenseCountry ? getNormalizeVehicleCountry(vehicleDetails?.licenseCountry) : countryOptions[0],
      isWizard: true,
      autoSyncData: vehicleDetails.autoSyncData,
      imageUrl: vehicleDetails.imageUrl || constants.EMPTY_STRING,
      vehicleDetails: {},
      isManual: !vehicleDetails.isIhs,
      isActive: true,
      fitmentId: vehicleDetails.fitmentVehicleId,
      startDate: vehicleDetails.startDate,
      endDate: vehicleDetails.endDate,
      isOneTime: vehicleDetails.isOneTime || false,
      isStaggered: tireDetails?.isStaggered || false,
      isDually: tireDetails?.isDually || false,
      year: vehicleDetails.year ? { label: vehicleDetails.year, value: vehicleDetails.year } : null,
      make: vehicleDetails.make ? { label: vehicleDetails.make, value: vehicleDetails.make } : null,
      model: vehicleDetails.model ? { label: vehicleDetails.model, value: vehicleDetails.model } : null,
      color: vehicleDetails.color ? { label: vehicleDetails.color?.toString()[0].toUpperCase() + vehicleDetails.color?.toString().slice(1), value: vehicleDetails.color?.toString()[0].toUpperCase() + vehicleDetails.color?.toString().slice(1) } : constants.EMPTY_STRING,
      trim: vehicleDetails.trimDescription || constants.EMPTY_STRING,
      trimId: vehicleDetails.trimId || constants.EMPTY_STRING,
      trimLift: vehicleDetails.fitmentLiftedId || constants.EMPTY_STRING,
      trimDescription: vehicleDetails.trimDescription || constants.EMPTY_STRING,
      assemblyCollection: vehicleDetails.assemblyCollection,
      selectedAssembly: selectedAssembly ?
        !vehicleDetails?.isStaggered ?
          {
            assemblyCode: selectedAssembly.assemblyItem.assemblyCode,
            assemblyId: selectedAssembly.assemblyItem.assemblyId,
            assemblyType: selectedAssembly.assemblyItem.assemblyType,
            frontRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.frontRimSize) ? selectedAssembly.assemblyItem.frontRimSize : constants.EMPTY_STRING,
            frontTireAspectRatio: selectedAssembly.assemblyItem.frontTireAspectRatio,
            frontTireDiameter: selectedAssembly.assemblyItem.frontTireDiameter,
            frontTireWidth: selectedAssembly.assemblyItem.frontTireWidth,
            rearRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.rearRimSize) ? selectedAssembly.assemblyItem.rearRimSize : constants.EMPTY_STRING,
            rearTireAspectRatio: selectedAssembly.assemblyItem.rearTireAspectRatio,
            rearTireDiameter: selectedAssembly.assemblyItem.rearTireDiameter,
            rearTireWidth: selectedAssembly.assemblyItem.rearTireWidth,
            tireSize: `${selectedAssembly.assemblyItem.frontTireWidth}/${selectedAssembly.assemblyItem.frontTireAspectRatio} R${selectedAssembly.assemblyItem.frontTireDiameter}`,
            wheelSize: selectedAssembly.assemblyItem.frontTireDiameter,
            isOE: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
              ? vehicleDetails.assemblyCollection?.some(a => {
                return (a.frontTireWidth === selectedAssembly.assemblyItem.frontTireWidth
                && a.frontTireAspectRatio === selectedAssembly.assemblyItem.frontTireAspectRatio
                && a.frontTireDiameter === selectedAssembly.assemblyItem.frontTireDiameter
                && a.assemblyCode === selectedAssembly.assemblyItem.assemblyCode);
              })
              : selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE,
            isAfterMarket: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE,
            isWinterUse: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE,
            sizeQualifier: isNullOrUndefined(selectedAssembly.assemblyItem.sizeQualifier) ? '0' : selectedAssembly.assemblyItem.sizeQualifier,
          } : [
            {
              assemblyCode: selectedAssembly.assemblyItem.assemblyCode,
              assemblyId: selectedAssembly.assemblyItem.assemblyId,
              assemblyType: selectedAssembly.assemblyItem.assemblyType,
              frontRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.frontRimSize) ? selectedAssembly.assemblyItem.frontRimSize : constants.EMPTY_STRING,
              frontTireAspectRatio: selectedAssembly.assemblyItem.frontTireAspectRatio,
              frontTireDiameter: selectedAssembly.assemblyItem.frontTireDiameter,
              frontTireWidth: selectedAssembly.assemblyItem.frontTireWidth,
              rearRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.rearRimSize) ? selectedAssembly.assemblyItem.rearRimSize : constants.EMPTY_STRING,
              rearTireAspectRatio: selectedAssembly.assemblyItem.rearTireAspectRatio,
              rearTireDiameter: selectedAssembly.assemblyItem.rearTireDiameter,
              rearTireWidth: selectedAssembly.assemblyItem.rearTireWidth,
              tireSize: `${selectedAssembly.assemblyItem.frontTireWidth}/${selectedAssembly.assemblyItem.frontTireAspectRatio} R${selectedAssembly.assemblyItem.frontTireDiameter}`,
              wheelSize: selectedAssembly.assemblyItem.frontTireDiameter,
              isOE: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
                ? vehicleDetails.assemblyCollection?.some(a => {
                  return (a.frontTireWidth === selectedAssembly.assemblyItem.frontTireWidth
                  && a.frontTireAspectRatio === selectedAssembly.assemblyItem.frontTireAspectRatio
                  && a.frontTireDiameter === selectedAssembly.assemblyItem.frontTireDiameter
                  && a.assemblyCode === selectedAssembly.assemblyItem.assemblyCode);
                })
                : selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE,
              isAfterMarket: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE,
              isWinterUse: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE,
              sizeQualifier: selectedAssembly.assemblyItem.sizeQualifier || '0',
            },
            {
              assemblyCode: selectedAssembly.assemblyItem.assemblyCode,
              assemblyId: selectedAssembly.assemblyItem.assemblyId,
              assemblyType: selectedAssembly.assemblyItem.assemblyType,
              frontRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.frontRimSize) ? selectedAssembly.assemblyItem.frontRimSize : constants.EMPTY_STRING,
              frontTireAspectRatio: selectedAssembly.assemblyItem.frontTireAspectRatio,
              frontTireDiameter: selectedAssembly.assemblyItem.frontTireDiameter,
              frontTireWidth: selectedAssembly.assemblyItem.frontTireWidth,
              rearRimSize: !isNullOrUndefined(selectedAssembly.assemblyItem.rearRimSize) ? selectedAssembly.assemblyItem.rearRimSize : constants.EMPTY_STRING,
              rearTireAspectRatio: selectedAssembly.assemblyItem.rearTireAspectRatio,
              rearTireDiameter: selectedAssembly.assemblyItem.rearTireDiameter,
              rearTireWidth: selectedAssembly.assemblyItem.rearTireWidth,
              tireSize: `${selectedAssembly.assemblyItem.rearTireWidth}/${selectedAssembly.assemblyItem.rearTireAspectRatio} R${selectedAssembly.assemblyItem.rearTireDiameter}`,
              wheelSize: selectedAssembly.assemblyItem.rearTireDiameter,
              isOE: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE
                ? vehicleDetails.assemblyCollection?.some(a => {
                  return (a.rearTireWidth === selectedAssembly.assemblyItem.rearTireWidth
                  && a.rearTireAspectRatio === selectedAssembly.assemblyItem.rearTireAspectRatio
                  && a.rearTireDiameter === selectedAssembly.assemblyItem.rearTireDiameter
                  && a.assemblyCode === selectedAssembly.assemblyItem.assemblyCode);
                })
                : selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE,
              isAfterMarket: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE,
              isWinterUse: selectedAssembly.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE,
              sizeQualifier: selectedAssembly.assemblyItem.sizeQualifier || '0',
            }
          ] :
        {},
      notes: vehicleDetails.notes || constants.EMPTY_STRING,
      vehicleId: vehicleDetails.vehicleId || constants.EMPTY_STRING,
      chassisCode: vehicleDetails.chassisCode || constants.EMPTY_STRING,
      relationshipId: vehicleDetails.relationshipId,
    };

    // is vehicle is incomplete, make sure to remove empty/incorrect fields
    if (vehicleDetails?.isIncomplete) {
      if (!vehicleObj.year) {
        delete vehicleObj.year;
        delete vehicleObj.make;
        delete vehicleObj.model;
        delete vehicleObj.trim;
        vehicleObj.assemblyCollection = [];
      } else if (!vehicleObj.make) {
        delete vehicleObj.model;
        delete vehicleObj.trim;
        vehicleObj.assemblyCollection = [];
      } else if (!vehicleObj.model) {
        delete vehicleObj.trim;
        vehicleObj.assemblyCollection = [];
      } else if (isNullEmptyUndefined(vehicleObj.trim) && isNullEmptyUndefined(vehicleObj.trimDescription) && isNullEmptyUndefined(vehicleObj.trimId)) {
        vehicleObj.assemblyCollection = [];
      }

      if (vehicleObj.assemblyCollection?.length > 0) {
        vehicleObj.assemblyCollection[0].assemblyItem.isCVM = false;
      }
    }

    return vehicleObj;
  }
  return {
    vin: constants.EMPTY_STRING,
    licensePlate: constants.EMPTY_STRING,
    licenseState: constants.EMPTY_STRING,
    licenseCountry: countryOptions[0],
    isWizard: false,
    vehicleDetails: {},
    isManual: false,
    assemblyCollection: {},
    selectedAssembly: {},
  };
};
export const initialAssemblyDetails = (details) => {
  const tireData = details?.vehicleTireData ?  details?.vehicleTireData[0] : {};
  let assembly;
  if (tireData.isStaggered) {
    tireData.assemblyConfiguration = constants.ASSEMBLY_VALUES[2].value;
  } else if (tireData.isDually) {
    tireData.assemblyConfiguration = constants.ASSEMBLY_VALUES[1].value;
  } else {
    tireData.assemblyConfiguration = constants.ASSEMBLY_VALUES[0].value;

  }
  const assemblyDetails = {
    assemblyConfiguration: tireData?.assemblyConfiguration
      ? constants.ASSEMBLY_VALUES.filter(option => option.value === tireData?.assemblyConfiguration)[0]
      : constants.ASSEMBLY_VALUES[0],
    tireWidth: tireData.frontTireWidth || constants.EMPTY_STRING,
    tireRatio: tireData.frontTireAspectRatio || constants.EMPTY_STRING,
    tireDiameter: tireData.frontTireDiameter || constants.EMPTY_STRING,
    tirePressure: tireData?.frontTirePressure || constants.EMPTY_STRING,
    rearTirePressure: tireData?.rearTirePressure
      ? tireData?.rearTirePressure : tireData?.tirePressure || constants.EMPTY_STRING,
    wheelWidth: tireData?.frontRimSize ? Math.trunc(tireData?.frontRimSize) : constants.EMPTY_STRING,
    wheelBoltPattern: tireData?.boltPattern || constants.EMPTY_STRING,
    wheelLugNutSize: tireData?.lugNutSize || constants.EMPTY_STRING,
    wheelTorque: tireData?.torque || constants.EMPTY_STRING,
    hubBore: tireData?.hubBore || constants.EMPTY_STRING,
    notes: tireData?.notes || constants.EMPTY_STRING,
  };
  let staggeredAssemblyDetails = {};
  if (tireData.assemblyConfiguration === 'staggered') {
    staggeredAssemblyDetails = {
      rearTireWidth: tireData.rearTireWidth || constants.EMPTY_STRING,
      rearTireRatio: tireData.rearTireAspectRatio || constants.EMPTY_STRING,
      rearTireDiameter: tireData.rearTireDiameter || constants.EMPTY_STRING,
      rearWheelSize: tireData?.rearWheelSize || constants.EMPTY_STRING,
      rearWheelWidth: tireData?.rearRimSize ? Math.trunc(tireData?.rearRimSize) : constants.EMPTY_STRING,
      rearWheelBoltPattern: tireData?.rearBoltPattern || constants.EMPTY_STRING,
      rearWheelLugNutSize: tireData?.rearLugNutSize || constants.EMPTY_STRING,
      rearWheelTorque: tireData?.rearTorque || constants.EMPTY_STRING,
    };
  }
  return { ...assemblyDetails, ...staggeredAssemblyDetails };
};
export const phoneOptions = phoneTypeOptions.map((key) => (
  { value: key.value, label: key.label }
));
export const filterCriteriaOptions = filterCriteriaTypeOptions.map((key) => (
  { value: key.value, label: key.label }
));
export const contactOptions = contactTypeOptions.map((key) => (
  { value: key.value, label: key.label }
));
export const suffixOptions = suffixTypeOptions.map((key) => (
  { value: key.value, label: key.label }
));
export const colorOptions = vehicleColorOptions.map((key) => (
  { value: key.value, label: key.label }
));
export const getSuffixOption = (value) => suffixOptions.filter(
  (option) => option.label === value,
);
export const getContactOption = (value) => contactOptions.filter(
  (option) => option.label === value,
);
export const getZipCodeLabel = (countryName, isCreate) => {
  if (!isCreate || countryName === constants.USA || countryName === 'US' || countryName === constants.UNITED_STATES) {
    return constants.ZIP_CODE_LABEL;
  }
  return constants.POSTAL_CODE_LABEL;
};
export const vehicleSubCategories = (options) => options?.map(option => (
  { value: option.value, label: option.label }
));
export const getStateOrProvincePlaceholder = (countryName) => {
  if (countryName === constants.USA || countryName === 'US' || countryName === constants.UNITED_STATES) {
    return constants.TITLE_STATE;
  }
  return constants.TITLE_PROVINCE;
};
export const objectsAreEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
export const convert1DArrayToDDArray = (arr) => {
  return arr.map(c => {
    return {
      value: c,
      label: c
    };
  });
};
export const breadCrumbs = [
  {
    name: constants.BREADCRUMB_YEAR,
    active: true,
    filled: false,
    disabled: false,
  }, {
    name: constants.BREADCRUMB_MAKE,
    active: false,
    filled: false,
    disabled: false,
  }, {
    name: constants.BREADCRUMB_MODEL,
    active: false,
    filled: false,
    disabled: false,
  }, {
    name: constants.BREADCRUMB_TRIM,
    active: false,
    filled: false,
    disabled: false,
  }, {
    name: constants.BREADCRUMB_TIRE_SIZE,
    active: false,
    filled: false,
    disabled: false,
  },
];
export const oeTireSizes = [
  {
    tiresize: '265/70 R17',
    wheel: '17',
  },
];
export const staggeredTireSizes = [{
  tiresize: {
    front: '265/70 R17 SL',
    back: '265/75 R18 SL',
  },
  wheel: {
    front: '17',
    back: '18',
  },
}];
export const duallyTireSizes = [{
  tiresize: {
    front: '265/70 R17 SL',
    back: {
      outer: '265/75 R18 SL',
      inner: '265/75 R18 SL',
    },
  },
  wheel: {
    front: '17',
    back: {
      outer: '18',
      innder: '18',
    },
  },
}];
export const additionalStandardTireSizeOptions = [
  {
    tiresize: '255/70 R16 SL',
    wheel: '16',
  },
  {
    tiresize: '255/70 R17 SL',
    wheel: '17',
  },
  {
    tiresize: '265/75 R17 SL',
    wheel: '17',
  },
  {
    tiresize: '275/80 R17 SL',
    wheel: '17',
  }, {
    tiresize: '265/70 R18 SL',
    wheel: '18',
  }, {
    tiresize: '265/70 R19 SL',
    wheel: '19',
  }, {
    tiresize: '265/70 R20 SL',
    wheel: '20',
  },
];
export const additionalStaggeredTireSizeOptions = [
  {
    tiresize: {
      front: '265/70 R17 SL',
      back: '265/75 R18 SL',
    },
    wheel: {
      front: '17',
      back: '18',
    },
  },
  {
    tiresize: {
      front: '265/70 R18 SL',
      back: '265/75 R19 SL',
    },
    wheel: {
      front: '18',
      back: '19',
    },
  },
  {
    tiresize: {
      front: '265/70 R19 SL',
      back: '265/75 R20 SL',
    },
    wheel: {
      front: '19',
      back: '20',
    },
  },
  {
    tiresize: {
      front: '265/70 R20 SL',
      back: '265/75 R21 SL',
    },
    wheel: {
      front: '20',
      back: '21',
    },
  },
];
export const wheelSizes = ['16', '17', '18', '19', '20', '21', '22', '23'];
export const taxStatusColors = {
  APPROVED: 'green',
  PENDING: 'orange',
  REJECTED: 'red',
  EXPIRED: 'red',
};

export const defaultInspectionQuery = (date) => {
  const startDate = moment(date || {}).startOf('day').hours(5).utc();
  const endDate = moment(date || {}).startOf('day').hours(22).utc();

  return {
    siteId: parseInt(localStorage.getItem('siteId'), 10),
    startTime: `${startDate.format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
    endTime: `${endDate.format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
  };
};
export const gkData = {
  'type': 'cvm-gk-request',
  'version': constants.DEFAULT_SCHEMA_VERSION,
  'customer': {
    'customerId': '',
    'customerType': '',
    'customerFirstName': '',
    'customerLastName': '',
    'customerMiddleInitial': '',
    'customerSuffix': '',
    'address': '',
    'addressLine2': '',
    'postalCode': '',
    'city': '',
    'state': '',
    'country': '',
    'SRPMICNumber': null,
    'alternateIdCollection': [],
    'phoneCollection': [{
      'phoneType': '',
      'phoneNumber': '',
      'phoneExtension': '',
      'internationalCode': '',
      'nationalCode': ''
    }],
    'customerEmailAddress': '',
    'customerEmailAddressType': '',
    'companyName': '',
    'companyName2': '',
    'businessPrimaryPurchasingPhoneCollection': [{
      'businessPhoneType': '',
      'businessPhoneNumber': '',
      'businessPhoneExtension': ''
    }],
    'businessPrimaryPurchasingFirstName': '',
    'businessPrimaryPurchasingLastName': '',
    'businessPrimaryPurchasingEmail': '',
    'arAccountNumber': '',
    'salesforceId': '',
    'workorderAuth': '',
    'paymentType': '',
    'blockedReasonCode': '',
    'postingBlock': '',
    'certificatesRequired': '',
    'poRequired': '',
    'taxExemptCertificateId': '',
    'taxExemptType': '',
    'taxExemptState': '',
    'taxExemptStatus': '',
    'employeeId': '',
    'privateLabelCardholder': true,
    'myAccount': true,
    'military': false,
    'aaaMembershipNumber': '',
    'offlineCreation': true,
    'businessAdditionalEmailCollection': [],
    'b2bType': null
  },
  'vehicle': {
    'driverName': '',
    'driverEmail': '',
    'vehicleType': '',
    'customerVehicleID': '',
    'fitmentVehicleId': '',
    'vehicleYear': null,
    'vehicleMake': '',
    'vehicleModel': '',
    'fitmentTrimId': '',
    'vehicleTrim': '',
    'fitmentChassisId': '',
    'fitmentAssemblyId': '',
    'fitmentLiftId': '',
    'frontTireSize': '',
    'vehicleColor': '',
    'vehicleComment': '',
    'vehicleImage': '',
    'genericVehicleCategory': null,
    'genericVehicleSubcategory': null,
    'genericFrontBoltPattern': '',
    'genericRearBoltPattern': '',
    'genericFrontLugNut': '',
    'genericRearLugNut': '',
    'genericFrontTorque': '',
    'genericRearTorque': '',
    'vehicleLicensePlate': '',
    'vehicleLicensePlateState': '',
    'vehicleLicensePlateCountry': '',
    'vehicleVIN': '',
    'oneTimeVehicleIndicator': false,
    'vtvId': '',
    'vehicleMileage': '00000',
    'carryOut': false,
    'assemblyType': '',
    'frontTireRatio': 0,
    'frontTireDiameter': 0,
    'frontRimSize': 0,
    'frontTireWidth': 0,
    'rearTireRatio': 0,
    'rearTireDiameter': 0,
    'rearRimSize': 0,
    'rearTireSize': '',
    'rearTireWidth': 0,
    'frontTirePressureGeneric': 0,
    'rearTirePressureGeneric': 0,
    'tires': [
      {
        'position': '',
        'dotNumber': '',
        'overallTreadDepth': ''
      },
      {
        'position': '',
        'dotNumber': '',
        'overallTreadDepth': ''
      },
      {
        'position': '',
        'dotNumber': '',
        'overallTreadDepth': ''
      },
      {
        'position': '',
        'dotNumber': '',
        'overallTreadDepth': ''
      }
    ]
  },
  'charge': {
  }
};

export const ChargeBlocks = [
  null,
  {
    'driverName': {
      'fieldNumber': '01',
      'fieldName': 'DRIVER NAME'
    }
  },
  {
    'companyName': {
      'fieldNumber': '02',
      'fieldName': 'COMPANY NAME'
    }
  },
  {
    'businessStreetAddress': {
      'fieldNumber': '03',
      'fieldName': 'STREET ADDRESS'
    }
  },
  {
    'businessCity': {
      'fieldNumber': '04',
      'fieldName': 'CITY'
    }
  },
  {
    'businessState': {
      'fieldNumber': '05',
      'fieldName': 'STATE'
    }
  },
  {
    'businessZip': {
      'fieldNumber': '06',
      'fieldName': 'ZIP'
    }
  },
  {
    'vehicleYear': {
      'fieldNumber': '07',
      'fieldName': 'VEHICLE YEAR'
    }
  },
  {
    'vehicleMake': {
      'fieldNumber': '08',
      'fieldName': 'VEHICLE MAKE'
    }
  },
  {
    'vehicleModel': {
      'fieldNumber': '09',
      'fieldName': 'VEHICLE MODEL'
    }
  },
  {
    'vehicleLicensePlateNumber': {
      'fieldNumber': '10',
      'fieldName': 'LICENSE PLATE #'
    }
  },
  {
    'vehicleLicensePlateState': {
      'fieldNumber': '11',
      'fieldName': 'LIC PLATE STATE'
    }
  },
  {
    'vin': {
      'fieldNumber': '12',
      'fieldName': 'Vehicle\'s VIN'
    }
  },
  {
    'mileage': {
      'fieldNumber': '13',
      'fieldName': 'MILEAGE'
    }
  },
  {
    'fleetCardNumber': {
      'fieldNumber': '14',
      'fieldName': 'FLEET CARD #'
    }
  },
  {
    'stockNumber': {
      'fieldNumber': '15',
      'fieldName': 'STOCK #'
    }
  },
  {
    'unitNumber': {
      'fieldNumber': '16',
      'fieldName': 'UNIT #'
    }
  },
  {
    'fleetNumber': {
      'fieldNumber': '17',
      'fieldName': 'FLEET #'
    }
  },
  {
    'driverEmail': {
      'fieldNumber': '18',
      'fieldName': 'DRIVER EMAIL'
    }
  },
  {
    'jobNumber': {
      'fieldNumber': '19',
      'fieldName': 'ID/JOB #'
    }
  },
  {
    'driverLicenseNumber': {
      'fieldNumber': '20',
      'fieldName': 'DRIVERS LICENSE'
    }
  },
  {
    'poRequired': {
      'fieldNumber': '21',
      'fieldName': 'CUSTOMER PO NUMBER'
    }
  }
];


export const mockInspectionData = {
  'customerDroppingOff': false,
  'dotCommunicationOptIn': false,
  'inspectionId': '',
  'isCarryOut': false,
  'isSpareInUse': false,
  'objectType': 'INSPECTION',
  'reason': 'service',
  'replaceAllTires': false,
  'replaceAllWheels': false,
  'rotationPattern': 'None',
  'source': 'CVM',
  'tpmsStatus': 'Off',
  'wheelLock': false,
  'yearKey': moment().format('YYYY'),
  'siteId': 2080,
  'audit': {
    'dateTime': moment.utc().format('MMDDYYYYHHmmss'),
    'deviceId': 'TC75x',
    'employeeNumber': '577467',
    'source': 'CVM',
    'storeCode': 'AZP 57',
    'siteId': 2080,
  },
  'customer': {
    'customerId': '4204236',
    'firstName': 'Noel',
    'lastName': 'Loera TEST 4',
    'phone': '4808849899',
    // New fields test
    'customerType': 'B2C',
    'companyName': 'Test Company Name',
    'companyName2': 'Test Company Name 2',
  },
  'vehicle': {
    'frontAssembly': 'n/a',
    'imageURL': 'https://cdn.discounttire.com/sys-master/images/he6/h7d/8932497686558/VEHICLE_201811211225_6687_17062.png_dt-vehicle-default-format_dt-vehicle-large',
    'isDualRearWheel': false,
    'isNonOE': false,
    'isStaggered': false,
    'vehicleId': '4272157',
    'year': '2012',
    'make': 'Honda',
    'model': 'Odyssey',
    'trimId': '15549',
    'trimName': 'EX',
    'overrideVehicleCategory': 'AGRICULTURE',
    'overrideVehicleSubCategory': 'TRACTOR',
    'overrideYear': 2024,
    'overrideMake': 'Test Override Make',
    'overrideModel': 'Test Override Model',
  },
  'tireCollection': [],
  'auditDateTime': `${moment.utc().format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
};
